<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb class="level-left mb-0">
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros">Centros</b-breadcrumb-item>
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros/editar" active>
                            Portal Público
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </b-breadcrumb>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <div class="mb-4">
                <BotoneraOpciones class="mb-6"></BotoneraOpciones>
            </div>

            <div class="columns is-desktop">
                <div class="column">
                    <b-field label="Código del portal">
                        <b-input
                            v-model="uuid"
                            autofocus
                            maxlength="50"
                            icon="form-textbox"
                            required
                            ref="codigo_portal"
                            readonly
                            disabled></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Usuario">
                        <Select2Component v-model="usuarioId" required class="w100-child" :options="usuarioOpciones">
                            <!-- <option v-for="usuario in usuarios" :value="usuario.usuarioId" :key="usuario.usuarioId">
                                {{ usuario.apellidos + ", " + usuario.nombre }}
                            </option> -->
                        </Select2Component>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field label="Texto de bienvenida">
                        <b-input
                            autofocus
                            maxlength="100"
                            icon="card-text"
                            required
                            ref="texto_bienvenida"
                            v-model="textoBienvenida"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <h2 class="title is-4">Permisos</h2>
                    <hr class="mb-1" />
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Expedientes">
                        <b-switch v-model="expedientes" @input="reseteaValoresExpedientes"> Activo </b-switch>
                    </b-field>
                    <b-field class="pl-5">
                        <b-switch
                            v-model="expedientesCrear"
                            :disabled="activaExpedientes"
                            @input="reseteaValoresExpedientes">
                            Puede crear
                        </b-switch>
                    </b-field>
                    <b-field class="pl-5">
                        <b-switch v-model="expedientesConsultar" :disabled="activaConsulta"> Puede consultar </b-switch>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Documentos">
                        <b-switch v-model="documentos"> Activo </b-switch>
                    </b-field>
                    <b-field label="Encuestas">
                        <b-switch v-model="encuestas"> Activo </b-switch>
                    </b-field>
                </div>
            </div>
        </div>
        <div class="level flotarDcha">
            <div class="level-right">
                <b-button
                    outlined
                    type="is-danger is-dark"
                    icon-pack="fas"
                    icon-left="ban"
                    class="mr-2 level-item"
                    @click="cancelar">
                    Cancelar
                </b-button>
                <b-button type="is-primary" native-type="submit" class="is-right" @click="guardar">
                    Guardar
                    <span class="icon ml-2">
                        <i class="fas fa-save"></i>
                    </span>
                </b-button>
            </div>
        </div>
    </app-layout>
</template>
<script>
import BotoneraOpciones from "@/views/gestionCentros/BotoneraOpciones.vue";
import { uuid } from "vue-uuid";
import apiGesuto from "@/services/gesuto.js";

export default {
    name: "PublicFormulario",
    data() {
        return {
            portalId: null,
            uuid: uuid.v1().toUpperCase(),
            textoBienvenida: "Bienvenido a ESG Public",
            expedienteDesactivado: true,
            expedienteConsultarDesactivado: true,
            documentos: false,
            expedientes: false,
            expedientesCrear: false,
            expedientesConsultar: false,
            encuestas: false,
            usuarios: null,
            usuarioId: null,
            usuarioOpciones: [],
        };
    },
    components: {
        BotoneraOpciones,
    },
    computed: {
        activaExpedientes() {
            return !this.expedientes;
        },
        activaConsulta() {
            return !this.expedientesCrear;
        },
    },
    methods: {
        reseteaValoresExpedientes() {
            if (!this.expedientes) {
                this.expedientesCrear = false;
                this.expedientesConsultar = false;
            }
            if (!this.expedientesCrear) {
                this.expedientesConsultar = false;
            }
        },
        cancelar() {
            this.$router.push({ path: "/gestion-centros" });
        },
        async guardar() {
            let estructura = {
                documentos: this.documentos ? 1 : 0,
                expedientes: {
                    puedeCrear: this.expedientesCrear ? 1 : 0,
                    puedeConsultar: this.expedientesConsultar ? 1 : 0,
                    activo: this.expedientes ? 1 : 0,
                },
                encuestas: this.encuestas ? 1 : 0,
                bienvenida: this.textoBienvenida,
                codigoPortal: this.uuid,
                centroId: this.$route.params.centroId,
                entornoId: this.$route.params.entornoId,
                usuarioId: this.usuarioId,
            };

            let response = await apiGesuto.guardarPortalCentro(estructura);
            if (response.error && response.error == "8DC5D9AB-F307-49A8-BBC4-393222D314F2") {
                this.showUserError("Debe seleccionar un usuario");
            } else if (response.message) {
                this.showUserMessage(response.message);
                this.$router.push({ path: "/gestion-centros" });
            }
        },
        showUserMessage(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: "is-success",
            });
        },
        showUserError(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: "is-danger",
            });
        },
    },

    async mounted() {
        let portal = await apiGesuto.cargarPortalCentro(this.$route.params.entornoId, this.$route.params.centroId);
        if (portal["error"]) {
            console.log("El portal no existe, créalo");
            // Pues me voy patras
        } else {
            if (portal.usuarios.length > 0) {
                this.usuarios = portal.usuarios;
                this.usuarios.forEach((element) => {
                    this.usuarioOpciones.push({
                        id: element.usuarioId,
                        text: element.apellidos + ", " + element.nombre,
                    });
                });
                this.usuarioOpciones.sort((a, b) => {
                    const textA = a.text
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                    const textB = b.text
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");

                    if (textA < textB) {
                        return -1;
                    }
                    if (textA > textB) {
                        return 1;
                    }
                    return 0;
                });
            }
            // A ver, esto es un chapú. Pero si el valor es 1 equivale a que el valor es nulo ?¿?¿?¿
            // así que se valida en la captura del valor del v-model
            if (portal.usuarioId != "1") {
                this.usuarioId = Number(portal.usuarioId);
            }
            if (portal.codigoPortal) {
                this.uuid = portal.codigoPortal;
                this.textoBienvenida = portal.bienvenida;
                this.documentos = portal.documentos ? true : false;
                this.expedientes = Number(portal.expedientes.activo) ? true : false;
                this.expedientesCrear = Number(portal.expedientes.puedeCrear) ? true : false;
                this.expedientesConsultar = Number(portal.expedientes.puedeConsultar) ? true : false;
                this.encuestas = portal.encuestas ? true : false;
                this.usuarios = portal.usuarios;
            }
        }
    },
};
</script>
