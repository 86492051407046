<template>
    <app-layout>
        <form @submit.prevent="guardarCentro" novalidate="true" ref="formularioCentro">
            <div class="box container is-fluid minh-100 pb-6">
                <div class="level mb-0">
                    <b-breadcrumb class="level-left mb-0">
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros">Centros</b-breadcrumb-item>
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros/editar" active
                            >{{ centro.dc_centro }} / Editar {{ seccion }}
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
                <div class="divider is-primary is-right mt-2"></div>
                <div class="mb-2">
                    <BotoneraOpciones class="mb-6"></BotoneraOpciones>
                </div>
                <ficha
                    v-if="seccion == 'ficha'"
                    :centroOrigen="centro"
                    :datosAuxiliares="datosAuxiliares"
                    ref="ficha"></ficha>
                <configuracion
                    v-if="seccion == 'configuracion'"
                    :centroOrigen="centro"
                    :featuresOrigen="features"
                    :datosAuxiliares="datosAuxiliares"
                    :entornoId="entornoId"
                    ref="configuracion"></configuracion>
                <aplicaciones
                    v-if="seccion == 'aplicaciones'"
                    :centroOrigen="centro"
                    :paquetes="datosAuxiliares.paquetes"
                    :aplicaciones="aplicacionesSeleccionadas"></aplicaciones>
                <tipos-expediente
                    v-if="seccion == 'expedientes'"
                    :centroOrigen="centro"
                    :tiposProcesosGenericos="tiposExpedientesGenericosSeleccionados"
                    :aplicaciones="aplicacionesSeleccionadas"
                    :supertiposNoCrear="superTipos"
                    :tiposExpedientesGuardados="tiposExpedientes"
                    :modoEdicionTipoExp="true"
                    ref="tiposExpediente"></tipos-expediente>
                <integraciones
                    v-if="seccion == 'integraciones'"
                    :centroOrigen="centro"
                    :datosAuxiliares="datosAuxiliares"
                    :conexiones="conexionesQlik"
                    :tiposQlik="servidoresQlik"></integraciones>
                <br />
                <br />
            </div>

            <div class="level flotarDcha">
                <div class="level-right">
                    <b-button
                        outlined
                        type="is-danger is-dark"
                        icon-pack="fas"
                        icon-left="ban"
                        @click="cancelarEdicion"
                        class="mr-2 level-item">
                        Cancelar
                    </b-button>
                    <b-button type="is-primary" native-type="submit" class="is-right">
                        Guardar
                        <span class="icon ml-2">
                            <i class="fas fa-save"></i>
                        </span>
                    </b-button>
                </div>
            </div>
        </form>
    </app-layout>
</template>

<script>
import BotoneraOpciones from "@/views/gestionCentros/BotoneraOpciones.vue";
import Ficha from "./formulario/Ficha.vue";
import Configuracion from "./formulario/Configuracion.vue";
import Aplicaciones from "./formulario/Aplicaciones.vue";
import TiposExpediente from "./formulario/TiposExpediente.vue";
import Integraciones from "./formulario/Integraciones.vue";
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";

export default {
    components: {
        Ficha,
        Configuracion,
        Aplicaciones,
        TiposExpediente,
        Integraciones,
        BotoneraOpciones,
    },
    name: "EditarCentro",
    computed: {
        aplicacionesSeleccionadas() {
            if (!this.cargaInicialAplicaciones) {
                return [];
            }
            return this.datosAuxiliares.aplicaciones;
        },
        tiposExpedientesGenericosSeleccionados() {
            if (!this.cargaInicialTiposExpedientesGenericos) {
                return [];
            }
            return this.datosAuxiliares.tiposProcesosGenericos;
        },
        servidoresQlik() {
            if (
                this.datosAuxiliares == undefined ||
                this.datosAuxiliares.tiposQlik == undefined ||
                this.datosAuxiliares.tiposQlik.servidores == undefined
            ) {
                return [];
            }
            return this.datosAuxiliares.tiposQlik.servidores;
        },
    },
    data() {
        return {
            seccion: this.$route.params.seccion,
            centroId: parseInt(this.$route.params.centroId),
            entornoId: parseInt(this.$route.params.entornoId),
            datosAuxiliares: {},
            centro: {},
            features: {
                bpm_analisis_causas_con_inteligencia_artificial: false,
                bpm_filtrado_entidades_relacionadas_por_jerarquia: false,
            },
            entornoDestino: "",
            erroresCampos: {
                ficha: 0,
                configuracion: 0,
            },
            aplicaciones: [],
            tiposExpedientes: [],
            tiposExpedientesGenericos: [],
            cargaInicialAplicaciones: false,
            cargaInicialTiposExpedientes: false,
            cargaInicialTiposExpedientesGenericos: false,
            conexionesQlik: [],
            superTipos: [],
        };
    },
    methods: {
        cargar(centroId, entornoId) {
            return apiCentros.cargar(centroId, entornoId).then((resultado) => {
                resultado.centro.fechaIni = this.convertirFecha(resultado.centro.fechaIni);
                resultado.centro.fechaFin = this.convertirFecha(resultado.centro.fechaFin);

                var lista_idiomas = [];
                if (resultado.idiomas != -1) {
                    lista_idiomas = resultado.idiomas.split(",");
                }
                var array_idiomas = this.obtenerIdiomas(lista_idiomas);
                resultado.centro.lista_idiomas = array_idiomas;
                this.aplicaciones = resultado.aplicaciones;
                this.superTipos = this.procesarSuperTipos(resultado.superTipos);
                this.tiposExpedientesGenericos = this.datosAuxiliares.tiposProcesosGenericos;
                this.tiposExpedientes = this.procesarTiposExpedientesEspecificos(resultado.tiposExpedientes);

                this.centro = resultado.centro;
                this.centro.solucionCentro = Number(resultado.solucionActual);
                this.conexionesQlik = resultado.conexionesQlikProcesadas;

                this.marcarAplicaciones();
                // this.marcarTiposExpedientes();
                this.marcarTiposExpedientesGenericos();
                this.tiposExpedientes = this.limpiarTiposExpedientesEspecificos(resultado.tiposExpedientes);
                this.features = resultado.features;
            });
        },
        convertirFecha(fecha) {
            var dateParts = fecha.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            return dateObject;
        },
        obtenerIdiomas(lista_idiomas) {
            var resultado = [];
            for (var idioma in lista_idiomas) {
                for (var objIdioma in this.datosAuxiliares.idiomas) {
                    if (lista_idiomas[idioma] == this.datosAuxiliares.idiomas[objIdioma].id_idioma) {
                        resultado.push(this.datosAuxiliares.idiomas[objIdioma]);
                    }
                }
            }
            return resultado;
        },
        cancelarEdicion() {
            this.$router.push({ path: "/gestion-centros" });
        },
        guardarCentro() {
            var centroForm = document.getElementsByTagName("form");
            this.erroresCampos.ficha = 0;
            this.erroresCampos.configuracion = 0;
            this.erroresCampos.tiposExpediente = 0;

            if (centroForm[0].checkValidity() == false) {
                var formulario = this.$refs;
                var res = false;
                for (var elemento in formulario) {
                    var hijos = formulario[elemento].$refs;
                    for (var hijo in hijos) {
                        if (typeof hijos[hijo] != "undefined") {
                            if (Array.isArray(hijos[hijo])) {
                                for (var nieto in hijos[hijo]) {
                                    res = hijos[hijo][nieto].checkHtml5Validity();
                                    if (!res) {
                                        this.erroresCampos[elemento]++;
                                    }
                                }
                            } else {
                                res = hijos[hijo].checkHtml5Validity();
                                if (!res) {
                                    this.erroresCampos[elemento]++;
                                }
                            }
                        }
                    }
                }
                if (
                    this.erroresCampos.ficha > 0 ||
                    this.erroresCampos.configuracion > 0 ||
                    this.erroresCampos.tiposExpediente > 0
                ) {
                    return;
                }
            }

            this.$buefy.dialog.confirm({
                title: "Atención",
                message: "¿Desea guardar el centro en el entorno <b>" + this.entornoDestino.nombre + "</b> ?",
                confirmText: "Guardar",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.procesarGuardar(),
            });
        },
        async procesarGuardar() {
            await apiCentros.guardarCentro({
                entornoId: this.entornoId,
                path: "centros/actualizar",
                seccion: this.seccion,
                centro: this.centro,
                paquetes: this.obtenerPaquetesSeleccionados(),
                aplicaciones: this.obtenerAplicacionesSeleccionados(),
                tiposExpedientesGenericos: this.obtenerTiposExpedientesGenericosSeleccionados(),
                conexiones: this.conexionesQlik,
                tiposExpedientes: this.actualizarTiposExpedientesSuperTipos(this.tiposExpedientes),
                features: this.features,
            });
            this.$router.push({ path: "/gestion-centros" });
        },
        marcarAplicaciones() {
            for (var aplicacionSeleccionada in this.aplicaciones) {
                for (var aplicacion in this.datosAuxiliares.aplicaciones) {
                    if (
                        this.aplicaciones[aplicacionSeleccionada].id_aplicacion ==
                        this.datosAuxiliares.aplicaciones[aplicacion].id_aplicacion
                    ) {
                        this.datosAuxiliares.aplicaciones[aplicacion].seleccionada = true;
                    }
                }
            }
            this.cargaInicialAplicaciones = true;
        },
        marcarTiposExpedientes() {
            for (var tipoExpedienteSeleccionado in this.tiposExpedientes) {
                for (var tipoExpediente in this.datosAuxiliares.tiposExpedientes) {
                    if (
                        this.tiposExpedientes[tipoExpedienteSeleccionado].id_tipoentrada ==
                        this.datosAuxiliares.tiposExpedientes[tipoExpediente].id
                    ) {
                        this.datosAuxiliares.tiposExpedientes[tipoExpediente].seleccionado = true;
                        this.tiposExpedientes[tipoExpedienteSeleccionado].seleccionado = true;
                    } else if (this.datosAuxiliares.tiposExpedientes[tipoExpediente].seleccionado === undefined) {
                        this.datosAuxiliares.tiposExpedientes[tipoExpediente].seleccionado = false;
                        this.tiposExpedientes[tipoExpedienteSeleccionado].seleccionado = false;
                    }
                }
            }
            this.cargaInicialTiposExpedientes = true;
        },
        marcarTiposExpedientesGenericos() {
            for (var tipoExpedienteSeleccionado in this.tiposExpedientes) {
                for (var tipoExpediente in this.datosAuxiliares.tiposProcesosGenericos) {
                    if (
                        this.tiposExpedientes[tipoExpedienteSeleccionado] !== undefined &&
                        this.tiposExpedientes[tipoExpedienteSeleccionado].codigo ==
                            this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].codigo &&
                        this.tiposExpedientes[tipoExpedienteSeleccionado].estado
                    ) {
                        this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].seleccionado = true;
                        this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].codigoSuperTipo =
                            this.tiposExpedientes[tipoExpedienteSeleccionado].supertipo;
                        this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].idTipoEntrada =
                            this.tiposExpedientes[tipoExpedienteSeleccionado].idTipoEntrada;
                        this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].idSuperTipo =
                            this.tiposExpedientes[tipoExpedienteSeleccionado].idSuperTipo;
                    } else if (this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].seleccionado === undefined) {
                        this.datosAuxiliares.tiposProcesosGenericos[tipoExpediente].seleccionado = false;
                    }
                }
            }
            this.datosAuxiliares.tiposProcesosGenericos = this.datosAuxiliares.tiposProcesosGenericos.sort(
                (a, b) => Number(b.seleccionado) - Number(a.seleccionado)
            );

            this.cargaInicialTiposExpedientesGenericos = true;
        },
        procesarTiposExpedientesEspecificos(tiposExpedientes) {
            var tiposExpedientesFormateados = [];
            for (var tipoExp in tiposExpedientes) {
                if (tiposExpedientes[tipoExp].estado) {
                    tiposExpedientesFormateados.push({
                        aplicacion: this.obtenerIdAplicacionPorCodigoExp(tiposExpedientes[tipoExp].codigo),
                        codigo: tiposExpedientes[tipoExp].codigo,
                        descripcion: tiposExpedientes[tipoExp].dl_tipoentrada,
                        editor: tiposExpedientes[tipoExp].editor,
                        nombre: tiposExpedientes[tipoExp].dc_tipoentrada,
                        supertipo: this.buscarSuperTipoPorId(tiposExpedientes[tipoExp].id_supertipo),
                        idTipoEntrada: tiposExpedientes[tipoExp].id_tipoentrada,
                        idSuperTipo: tiposExpedientes[tipoExp].id_supertipo,
                        estado: tiposExpedientes[tipoExp].estado,
                    });
                }
            }
            return tiposExpedientesFormateados;
        },
        limpiarTiposExpedientesEspecificos(tiposExpedientes) {
            var tiposExpedientesFormateados = [];
            for (var tipoExp in tiposExpedientes) {
                if (!this.esTipoGenerico(tiposExpedientes[tipoExp]) && tiposExpedientes[tipoExp].estado) {
                    tiposExpedientesFormateados.push({
                        aplicacion: this.obtenerIdAplicacionPorCodigoExp(tiposExpedientes[tipoExp].codigo),
                        codigo: tiposExpedientes[tipoExp].codigo,
                        descripcion: tiposExpedientes[tipoExp].dl_tipoentrada,
                        editor: tiposExpedientes[tipoExp].editor,
                        nombre: tiposExpedientes[tipoExp].dc_tipoentrada,
                        supertipo: this.buscarSuperTipoPorId(tiposExpedientes[tipoExp].id_supertipo),
                        idTipoEntrada: tiposExpedientes[tipoExp].id_tipoentrada,
                        idSuperTipo: tiposExpedientes[tipoExp].id_supertipo,
                        estado: tiposExpedientes[tipoExp].estado,
                    });
                }
            }
            return tiposExpedientesFormateados;
        },
        obtenerPaquetesSeleccionados() {
            var idsPaquetes = [];
            for (var aplicacion in this.datosAuxiliares.aplicaciones) {
                if (
                    this.datosAuxiliares.aplicaciones[aplicacion].seleccionada != undefined &&
                    this.datosAuxiliares.aplicaciones[aplicacion].seleccionada
                ) {
                    idsPaquetes.push(this.datosAuxiliares.aplicaciones[aplicacion].id_paquete);
                }
            }

            var paquetes = [];
            for (var paquete in this.datosAuxiliares.paquetes) {
                if (idsPaquetes.includes(this.datosAuxiliares.paquetes[paquete].id_paquete)) {
                    paquetes.push(this.datosAuxiliares.paquetes[paquete]);
                }
            }
            return paquetes;
        },
        obtenerTiposExpedientesGenericosSeleccionados() {
            var idsTiposExpedientes = [];
            for (var tipoProcesoGenerico in this.datosAuxiliares.tiposProcesosGenericos) {
                if (
                    this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].seleccionado != undefined &&
                    this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].seleccionado
                ) {
                    idsTiposExpedientes.push(this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenerico].id);
                }
            }

            var tiposProcesosGenericos = [];
            for (var tipoProcesoGenericoA in this.datosAuxiliares.tiposProcesosGenericos) {
                if (
                    idsTiposExpedientes.includes(this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA].id)
                ) {
                    this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA].idSuperTipo =
                        this.buscarSuperTipoPorCodigo(
                            this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA].codigoSuperTipo
                        );
                    tiposProcesosGenericos.push(this.datosAuxiliares.tiposProcesosGenericos[tipoProcesoGenericoA]);
                }
            }
            return tiposProcesosGenericos;
        },
        actualizarTiposExpedientesSuperTipos(tiposExpedientes) {
            var tiposProcesosGenericos = [];
            for (var tipoProceso in tiposExpedientes) {
                tiposExpedientes[tipoProceso].idSuperTipo = this.buscarSuperTipoPorCodigo(
                    tiposExpedientes[tipoProceso].supertipo
                );
                tiposProcesosGenericos.push(tiposExpedientes[tipoProceso]);
            }
            return tiposProcesosGenericos;
        },
        obtenerAplicacionesSeleccionados() {
            var aplicaciones = [];
            for (var aplicacion in this.datosAuxiliares.aplicaciones) {
                if (
                    this.datosAuxiliares.aplicaciones[aplicacion].seleccionada != undefined &&
                    this.datosAuxiliares.aplicaciones[aplicacion].seleccionada
                ) {
                    aplicaciones.push(this.datosAuxiliares.aplicaciones[aplicacion]);
                }
            }
            return aplicaciones;
        },
        buscarSuperTipoPorId(id) {
            for (var superTipo in this.superTipos) {
                if (this.superTipos[superTipo].id_superTipo == id) {
                    return this.superTipos[superTipo].CODIGO;
                }
            }
            return "";
        },
        buscarSuperTipoPorCodigo(codigo) {
            for (var superTipo in this.superTipos) {
                if (this.superTipos[superTipo].CODIGO == codigo) {
                    return this.superTipos[superTipo].id_superTipo;
                }
            }
            return "";
        },
        procesarSuperTipos(superTipos) {
            var res = [];
            for (var superTipo in superTipos) {
                res.push({
                    CODIGO: superTipos[superTipo].codigo,
                    NOMBRE: superTipos[superTipo].dc_supertipo,
                    id_superTipo: superTipos[superTipo].id_superTipo,
                });
            }
            return res;
        },
        obtenerIdAplicacionPorCodigoExp(codigo) {
            var res = 0;
            for (var app in this.aplicaciones) {
                var codigos = this.codigosAplicacion(this.aplicaciones[app].configuracion);
                if (codigos !== undefined && codigos.length > 0 && codigos.includes(codigo)) {
                    res = this.aplicaciones[app].id_aplicacion;
                }
            }
            return res;
        },
        codigosAplicacion(configuracion) {
            if (configuracion == "") return [];
            configuracion = JSON.parse(configuracion);
            if (configuracion.array_codigos !== undefined) {
                return configuracion.array_codigos;
            }
            if (configuracion.ARRAY_CODIGOS !== undefined) {
                return configuracion.ARRAY_CODIGOS;
            }
        },
        esTipoGenerico(tipoExpediente) {
            for (var tipoExp in this.tiposExpedientesGenericos) {
                if (this.tiposExpedientesGenericos[tipoExp].codigo == tipoExpediente.codigo) {
                    return true;
                }
            }
            return false;
        },
    },
    async mounted() {
        if (this.centroId) {
            this.entornoDestino = await apiEntornos.cargar(this.entornoId);
            this.entornoDestino = this.entornoDestino.entorno;
            this.datosAuxiliares = await apiCentros.cargarDatosAuxiliares(this.entornoId);
            this.cargar(this.centroId, this.entornoId);
        }
    },
    watch: {
        $route: function () {
            this.seccion = this.$route.params.seccion;
        },
    },
};
</script>
