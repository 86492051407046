import ListarPage from "@/views/expedientes/ListarPage.vue";
import CrearSupertipo from "@/views/expedientes/CrearSupertipo.vue";
import CrearTipo from "@/views/expedientes/CrearTipo.vue";

export default [
    {
        path: "/expedientes",
        component: ListarPage,
    },
    {
        path: "/expedientes/crear-supertipo/:entornoId/:centroId",
        component: CrearSupertipo,
    },
    {
        path: "/expedientes/crear-tipo/:entornoId/:centroId",
        component: CrearTipo,
    },
    {
        path: "/expedientes/editar-supertipo/:entornoId/:centroId/:supertipoId",
        component: CrearSupertipo,
    },
    {
        path: "/expedientes/editar-tipo/:entornoId/:centroId/:tipoExpedienteId",
        component: CrearTipo,
    },
];
