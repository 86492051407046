import ListarPage from "@/views/gestionCentros/ListarPage.vue";

export default [
    {
        path: "/gestion-centros",
        component: ListarPage,
    },
    {
        path: "/gestion-centros/editar/:seccion/:centroId/:entornoId",
        component: require("@/views/gestionCentros/EditarPage").default,
    },
    {
        path: "/gestion-centros/crear/:entornoId",
        component: require("@/views/gestionCentros/CrearPage").default,
    },
    {
        path: "/gestion-centros/portal/:centroId/:entornoId",
        component: require("@/views/gestionCentros/portal").default,
    },
    {
        path: "/gestion-centros/public/:centroId/:entornoId",
        component: require("@/views/gestionCentros/public").default,
    },
];
