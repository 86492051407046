<template>
    <div>
        <div class="columns is-desktop">
            <div class="column is-4 is-offset-2 pr-6">
                <b-field class="mb-4">
                    <b-switch v-model="centro.radar">Radar</b-switch>
                </b-field>
                <b-field v-show="centro.radar">
                    <b-select
                        placeholder="Seleccione Orden de ejecución"
                        icon="autorenew"
                        v-model="centro.orden_ejecucion_radar"
                        class="ml-4">
                        <option value="1" selected="">Check list</option>
                        <option value="2">Por Entidades</option>
                    </b-select>
                </b-field>
                <b-field v-show="centro.radar">
                    <b-switch v-model="centro.activar_limite_usuarios_radar"
                        >Activar límite de usuarios en Check list</b-switch
                    >
                </b-field>
                <b-field
                    v-show="centro.activar_limite_usuarios_radar && centro.radar"
                    label="Número máximo usuarios en Check list">
                    <b-numberinput
                        v-model="centro.numero_maximo_usuarios_radar"
                        :min="0"
                        icon="account-group"
                        placeholder="Número máximo usuarios en Check list"></b-numberinput>
                </b-field>
                <b-field v-show="centro.radar" label="Número máximo de checklists vigentes en Radar">
                    <b-numberinput
                        v-model="centro.max_checklists_vigentes"
                        :min="0"
                        icon="account-group"
                        placeholder="Número máximo de checklists vigentes en Radar"></b-numberinput>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.multisede">Multisede</b-switch>
                </b-field>
                <b-field v-show="centro.multisede">
                    <b-numberinput
                        v-model="centro.numero_maximo_entidades"
                        :min="1"
                        icon="office-building"
                        placeholder="Número máx entidades"></b-numberinput>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.vincular_entidad_grupo_org">
                        Vincular entidad grupo de organización
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Se generará un tipo de entidad en la aplicación de entidades llamado 'grupos
                                    de organización' cuyas entidades estarán relacionadas directamente con los
                                    departamentos de recursos humanos(ex6) y las personas de los departamentos
                                    relacionadas con los usuarios en dichas entidades. <br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.diversificar_por_entidad">Diversificar por entidad</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.diversificar_por_carpeta">Diversificar por carpeta</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.bloquear_roles">Bloquear roles</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.sincronizar_gcontactos">Sincronizar gestor de contactos</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.importacion_documentos">Importacion de Documentos</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.copiar_tipos_expedientes">Versionado de expedientes</b-switch>
                </b-field>
                <b-field label="Límite mínimo de caracteres en la contraseña (entre 7 y 30)">
                    <b-numberinput
                        v-model="centro.minimo_caracteres_pass"
                        :min="7"
                        :max="30"
                        icon="form-textbox-password"
                        required
                        ref="minimo_caracteres_pass"></b-numberinput>
                </b-field>
                <b-field label="Límite mínimo de fortaleza de la contraseña (entre 70 y 100)">
                    <b-numberinput
                        v-model="centro.strength_min_pass"
                        :min="70"
                        :max="100"
                        icon="form-textbox-password"
                        required
                        ref="strength_min_pass"></b-numberinput>
                </b-field>
                <b-switch v-model="centro.vistaCompleja">Activar vista compleja en Bpm</b-switch>
            </div>
            <div class="column is-4 pl-6">
                <b-field>
                    <b-switch v-model="centro.rrhh_permisos_campos">Activar permisos de campos rrhh</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.bpm_filtro_defecto">Activar filtro defecto últimos meses</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.seg_avanz_acc">Seguridad avanzada en accesos</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.login2fa">Activar login con doble factor de autenticación</b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.login2fa_recordar_dispositivo"
                        >Activar envío único por usuario al día</b-switch
                    >
                </b-field>
                <b-field>
                    <b-switch v-model="centro.import_manual_doc_exp">
                        Subida manual de documentos
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    Para la importación de expedientes cuando en la primera fase existen campos
                                    personalizados de tipo documento, para amenizar los tiempos, el zip con los
                                    documentos a relacionar, se subirá manualmente al servidor por ftp. <br />
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.correos_sin_hipervinculos">
                        Generar correos de aviso sin hipervínculos
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />En los correos que se generen no se incluirán hipervínculos <br /><span
                                        ><strong class="has-text-danger">Inactivo:</strong></span
                                    >
                                    <br />En los correos que se generen pueden incluirse hipervínculos
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.registros_multiples">
                        Registros múltiples
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Copia los campos personalizados de la primera fase de un expediente que
                                    seleccionemos como base para la creación de uno nuevo.<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.limit_diez_col_vista_bpm">
                        Limitar columnas listado BPM
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Limita a diez el número de columnas mostradas en el listado de procesos de
                                    BPM<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.centro_copia_controlada">
                        Copias controladas de documentos
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Se controla la copia/descarga/impresión de los documentos<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.limitarModelosGestion">
                        Limitar el número de modelos de gestión en Riesgos
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Permite especificar un número límite máximo de modelos de gestión que se
                                    pueden dar de alta en la aplicación de Riesgos<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field v-show="centro.limitarModelosGestion">
                    <b-numberinput
                        v-model="centro.numeroMaximoModelosRiesgos"
                        :min="0"
                        icon="alert"
                        placeholder="Número máximo modelos de gestión"></b-numberinput>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.centro_integridad_acciones">
                        Integridad en las acciones
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Este check permite que la autoasignación de la responsabilidad móvil en
                                    acciones, sea configurada en los procesos del centro.<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <b-field>
                    <b-switch v-model="centro.gdrive_bpm">
                        Uso de Google Drive
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />Permite vincular documentos de Google Drive en campos personalizados de tipo
                                    Documento. Además, permite traer documentos del Drive con extensiones: gdoc, gsheet
                                    y gslides, para tratarlos dentro del Gestor documental<br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <!-- Inteligencia Artificial -->
                <b-field>
                    <b-switch v-model="features.bpm_analisis_causas_con_inteligencia_artificial">
                        Analisis de causas mediante Inteligencia Artificial
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />
                                    Permite el uso de la inteligencia artificial para el análisis de causas y efectos en
                                    los expedientes del BPM<br />
                                    <br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
                <!-- Filtrado de entidade relacionadas por jerarquia -->
                <b-field>
                    <b-switch v-model="features.bpm_filtrado_entidades_relacionadas_por_jerarquia">
                        Filtrado de entidades relacionadas por jerarquía
                        <b-tooltip position="is-bottom" multilined type="is-white is-light" size="is-large">
                            <b-icon icon="information" size="is-small" type="is-primary"> </b-icon>
                            <template v-slot:content>
                                <div class="has-text-left has-text-black">
                                    <span><strong class="has-text-success">Activo:</strong></span>
                                    <br />
                                    Permite configurar un filtro en la relación de entidades a nivel de tipo de proceso
                                    o tipo de fase por la jerarquía que tienen estas entidades con la entidad usada en
                                    la diversificación del expediente.<br />
                                    <br />
                                    <span><strong class="has-text-danger">Inactivo:</strong></span>
                                    <br />No aplica
                                </div>
                            </template>
                        </b-tooltip>
                    </b-switch>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop mt-6">
            <div class="column is-8 is-offset-2 pr-6">
                <h3 class="title is-5">Centros Relacionados</h3>
                <div class="divider is-primary is-right mt-0"></div>
                <b-field class="mb-4">
                    <b-switch v-model="centro.tiene_correlativo_global">Centro con correlativo global</b-switch>
                </b-field>
                <b-field class="mb-4">
                    <b-switch v-model="centro.relacion_centros">Activar relación entre centros</b-switch>
                </b-field>
                <div id="div_centros_relacionados" v-show="centro.relacion_centros">
                    <div id="contenedor_todas_filas_centros_relacionados mb-6">
                        <div
                            class="row capas_fila_centro_relacionado columns is-desktop"
                            id="capa_padre_centro_relacionado_">
                            <div class="column is-3">
                                <b-field>
                                    <b-select
                                        placeholder="Seleccione un entorno"
                                        icon="autorenew"
                                        v-model="entornoSeleccionado"
                                        class="ml-4"
                                        @input="obtenerOpcionesComboCentroEntorno">
                                        <option v-for="entorno in entornos" :key="entorno.value" :value="entorno.value">
                                            {{ entorno.text }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-field>
                                    <b-select
                                        placeholder="Seleccione un centro"
                                        icon="earth"
                                        v-model="centroSeleccionado"
                                        class="ml-4">
                                        <option
                                            v-for="centroEntorno in centrosEntorno"
                                            :key="centroEntorno.valor"
                                            :value="centroEntorno.valor">
                                            {{ centroEntorno.texto }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-button
                                    type="is-primary is-light"
                                    icon-left="plus-circle-outline"
                                    outlined
                                    @click="anadirCentroRelacionado">
                                    Añadir
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="columns is-desktop pl-5 is-vcentered mb-0"
                        v-for="centroRelacionado in centro.centrosRelacionados"
                        :key="centroRelacionado.entornoCentro"
                        :value="centroRelacionado.entornoCentro">
                        <div class="column is-3">
                            {{ centroRelacionado.entorno }}
                        </div>
                        <div class="column is-6">
                            {{ centroRelacionado.centro }}
                        </div>
                        <div class="column is-2">
                            <b-button
                                type="is-danger"
                                icon-right="delete"
                                @click="borrarCentroRelacionado(centroRelacionado.entornoCentro)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column is-4 is-offset-2 pr-6"></div>
        </div>
    </div>
</template>
<script>
import apiCentros from "@/services/centros.js";
export default {
    name: "ConfiguracionFormulario",
    props: {
        entornoId: Number,
        centroOrigen: Object,
        featuresOrigen: Object,
    },
    computed: {
        centro() {
            return this.centroOrigen;
        },
        features() {
            return this.featuresOrigen;
        },
    },
    data() {
        return {
            numRelacionados: 0,
            centrosEntorno: [],
            centrosRelacionados: [],
            entornoSeleccionado: 0,
            centroSeleccionado: 0,
            entornos: [
                {
                    value: "appa",
                    text: "América",
                },
                {
                    value: "appacl",
                    text: "Chile",
                },
                {
                    value: "appaco",
                    text: "Colombia",
                },
                {
                    value: "appe",
                    text: "Europa",
                },
                {
                    value: "appape",
                    text: "Perú",
                },
            ],
            precarga: false,
        };
    },
    methods: {
        async obtenerOpcionesComboCentroEntorno() {
            this.centrosEntorno = await apiCentros.obtenerOpcionesComboCentroEntorno(
                this.entornoId,
                this.entornoSeleccionado
            );
        },
        anadirCentroRelacionado() {
            var nombreEntorno = this.obtenerNombreEntorno(this.entornoSeleccionado);
            var nombreCentro = this.obtenerNombreCentro(this.centroSeleccionado);
            var elemento = {
                entornoCentro: this.entornoSeleccionado + "_" + this.centroSeleccionado,
                entorno: nombreEntorno,
                centro: nombreCentro,
                entornoId: this.entornoSeleccionado,
                centroId: this.centroSeleccionado,
            };
            var indice = this.centro.centrosRelacionados.findIndex(
                (object) => object.entornoCentro === elemento.entornoCentro
            );
            if (indice === -1) {
                this.centro.centrosRelacionados.push(elemento);
            }
        },
        borrarCentroRelacionado(entornoCentro) {
            var indice = this.centro.centrosRelacionados.findIndex((object) => object.entornoCentro === entornoCentro);
            if (indice !== -1) {
                this.centro.centrosRelacionados.splice(indice, 1);
            }
        },
        obtenerNombreEntorno(entornoSeleccionado) {
            for (var entorno in this.entornos) {
                if (this.entornos[entorno].value == entornoSeleccionado) {
                    return this.entornos[entorno].text;
                }
            }
            return "";
        },
        obtenerNombreCentro(centroSeleccionado) {
            for (var centro in this.centrosEntorno) {
                if (this.centrosEntorno[centro].valor == centroSeleccionado) {
                    return this.centrosEntorno[centro].texto;
                }
            }
            return "";
        },
        async cargarCentrosRelacionados() {
            var arrayBorrar = [];

            for (var centro in this.centroOrigen.centrosRelacionados) {
                this.entornoSeleccionado = this.centro.centrosRelacionados[centro].ENTORNO_RELACIONADO;
                await this.obtenerOpcionesComboCentroEntorno();
                var nombreEntorno = this.obtenerNombreEntorno(
                    this.centro.centrosRelacionados[centro].ENTORNO_RELACIONADO
                );
                var nombreCentro = this.obtenerNombreCentro(
                    this.centro.centrosRelacionados[centro].ID_CENTRO_RELACIONADO
                );
                var elemento = {
                    entornoCentro:
                        this.centro.centrosRelacionados[centro].ENTORNO_RELACIONADO +
                        "_" +
                        this.centro.centrosRelacionados[centro].ID_CENTRO_RELACIONADO,
                    entorno: nombreEntorno,
                    centro: nombreCentro,
                    entornoId: this.centro.centrosRelacionados[centro].ENTORNO_RELACIONADO,
                    centroId: this.centro.centrosRelacionados[centro].ID_CENTRO_RELACIONADO,
                };
                arrayBorrar.push(centro);
                this.centro.centrosRelacionados.push(elemento);
            }
            arrayBorrar.reverse();
            for (var i = 0; i < arrayBorrar.length; i++) {
                this.centro.centrosRelacionados.splice(arrayBorrar[i], 1);
            }
        },
    },
    mounted() {},
    watch: {
        centro: {
            handler: function () {
                if (!this.precarga && this.centro.id_centro !== undefined && this.centro.id_centro != 0) {
                    this.cargarCentrosRelacionados();
                    this.precarga = true;
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
