<template>
    <b-table
        :data="isEmpty ? [] : data"
        :bordered="isBordered"
        :striped="isStriped"
        :narrowed="isNarrowed"
        :hoverable="isHoverable"
        :loading="isLoading"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="id"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Actual"
        :page-input="hasInput"
        :pagination-order="paginationOrder"
        :page-input-position="inputPosition"
        :debounce-page-input="inputDebounce">
        <b-table-column field="id" label="Num." width="40" numeric v-slot="props" sortable>
            {{ props.row.id }}
        </b-table-column>

        <b-table-column field="nombre" label="Centro" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.nombre }}
        </b-table-column>

        <b-table-column field="responsable" label="Responsable" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.responsable }}
        </b-table-column>

        <b-table-column field="fechaAlta" label="Fecha de Alta" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ fechaFormateada(props.row.fechaAlta) }}
        </b-table-column>

        <b-table-column field="sede" label="Sede" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.sede }}
        </b-table-column>

        <b-table-column field="pais" label="País" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.pais }}
        </b-table-column>

        <b-table-column label="Estado" v-slot="props" field="estado" sortable :searchable="mostrarFiltros">
            <span>
                {{ props.row.estado }}
            </span>
        </b-table-column>

        <b-table-column label="Acciones" v-slot="props">
            <b-dropdown position="is-bottom-left" aria-role="menu">
                <template #trigger="{ active }">
                    <b-button
                        type="is-primary"
                        icon-left="menu"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                        @click="pulsarLista" />
                </template>

                <b-dropdown-item value="personas" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/personas/${props.row.id}/${entorno}`">
                        <b-icon icon="account-group" class="mr-2"></b-icon>
                        Personas
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="entidades" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/entidades/${props.row.id}/${entorno}`">
                        <b-icon icon="database" class="mr-2"></b-icon>
                        Entidades
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="procesos" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/mapasProcesos/${props.row.id}/${entorno}`">
                        <b-icon icon="sitemap" class="mr-2"></b-icon>
                        Mapa de Procesos
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="bpm" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/bpm/${props.row.id}/${entorno}`">
                        <b-icon icon="sync" class="mr-2"></b-icon>
                        BPM
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="riesgos" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/riesgos/${props.row.id}/${entorno}`">
                        <b-icon icon="alert" class="mr-2"></b-icon>
                        Riesgos
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="indicadores" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/indicadores/${props.row.id}/${entorno}`">
                        <b-icon icon="speedometer" class="mr-2"></b-icon>
                        Indicadores
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="dashboard" aria-role="menuitem" has-link disabled>
                    <router-link :to="`/centros/dashboards/${props.row.id}/${entorno}`" is="span">
                        <b-icon icon="view-dashboard" class="mr-2"></b-icon>
                        Dashboard
                    </router-link>
                </b-dropdown-item>

                <b-dropdown-item value="encuestas" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/encuestas/${props.row.id}/${entorno}`">
                        <b-icon icon="list-status" class="mr-2"></b-icon>
                        Encuestas
                    </router-link>
                </b-dropdown-item>

                <b-dropdown-item value="proyectos" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/proyectos/${props.row.id}/${entorno}`">
                        <b-icon icon="briefcase" class="mr-2"></b-icon>
                        Proyectos
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="radar" aria-role="menuitem" has-link disabled>
                    <router-link :to="`/centros/radar/${props.row.id}/${entorno}`" is="span">
                        <b-icon icon="radar" class="mr-2"></b-icon>
                        Radar
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="contratistas" aria-role="menuitem" has-link disabled>
                    <router-link :to="`/centros/contratistas/${props.row.id}/${entorno}`" is="span">
                        <b-icon icon="wrench" class="mr-2"></b-icon>
                        Contratistas
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="bsc" aria-role="menuitem" has-link disabled>
                    <router-link :to="`/centros/bsc/${props.row.id}/${entorno}`" is="span">
                        <b-icon icon="scale-balance" class="mr-2"></b-icon>
                        BSC
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="report" aria-role="menuitem" has-link disabled>
                    <router-link :to="`/centros/reports/${props.row.id}/${entorno}`" is="span">
                        <b-icon icon="chart-bar" class="mr-2"></b-icon>
                        Report
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item value="etiquetasPermisos" aria-role="menuitem" has-link>
                    <router-link :to="`/centros/etiquetasPermisos/${props.row.id}/${entorno}`">
                        <b-icon icon="tag" class="mr-2"></b-icon>
                        Etiquetas de Permisos
                    </router-link>
                </b-dropdown-item>
            </b-dropdown>
        </b-table-column>
        <template #empty>
            <div class="has-text-centered">No hay resultados</div>
        </template>
    </b-table>
</template>
<script>
import { formatDateTabla } from "@/services/date";

export default {
    props: {
        resultado: {
            centros: [],
            resultadosPorPagina: 10,
            pagina: 1,
        },
        mostrarFiltros: Boolean,
        entorno: {},
    },
    computed: {
        data() {
            return this.resultado.centros;
        },
        currentPage: {
            get() {
                return this.paginaActual;
            },
            set(value) {
                this.paginaActual = value;
            },
        },
        perPage() {
            return this.resultado.resultadosPorPagina;
        },
    },
    data() {
        return {
            isEmpty: false,
            isBordered: false,
            isStriped: true,
            isNarrowed: false,
            isHoverable: true,
            isFocusable: false,
            isLoading: false,
            hasMobileCards: true,
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: false,
            paginationPosition: "bottom",
            defaultSortDirection: "asc",
            sortIcon: "arrow-up",
            sortIconSize: "is-small",
            hasInput: false,
            paginationOrder: "",
            inputPosition: "",
            inputDebounce: "",
            paginaActual: null,
        };
    },
    methods: {
        editarCentro(centroId) {
            this.$router.push("/centros/editar/" + centroId);
        },
        eliminarCentro(centroId) {
            this.$emit("eliminarCentro", centroId);
        },
        fechaFormateada(fecha) {
            if (!fecha) return "-";
            return formatDateTabla(fecha);
        },
        pulsarLista: function (event) {
            if (event) {
                var elemento = event.target.closest("div.dropdown-trigger").nextSibling.nextSibling;
                elemento.style.position = "fixed";
                elemento.style.top = event.clientY / 2.5 + "px";
                elemento.style.right =
                    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) -
                    event.target.closest("div.dropdown-trigger").getBoundingClientRect().right +
                    70 +
                    "px";
            }
        },
        filtrarEstado(row, input) {
            var estado = this.nombreEstado(row.estado).toLowerCase();
            return input && estado.includes(input.toLowerCase());
        },
    },
    async mounted() {
        this.paginaActual = this.resultado.paginaActual;
    },
};
</script>

<style>
.dropdown.dropdown-menu-animation.is-bottom-left.is-active.is-mobile-modal .button.is-primary {
    background-color: #888;
}

div.is-disabled > span {
    padding: 0.375rem 0.8rem;
    color: #888;
    line-height: 2.2rem;
}
</style>
