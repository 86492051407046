import Vue from "vue";
import Buefy from "buefy";
// import 'buefy/dist/buefy.css'
import VueObserveVisibility from "vue-observe-visibility";
import UUID from "vue-uuid";
import Select2 from "v-select2-component";

Vue.use(Buefy);
Vue.use(VueObserveVisibility);
Vue.use(UUID);
Vue.component("Select2Component", Select2);
