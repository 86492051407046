<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb-item tag="router-link" to="/expedientes">Gestión de expedientes</b-breadcrumb-item>
                    <b-breadcrumb-item
                        tag="router-link"
                        to="/expedientes/crear-tipo"
                        active
                        v-if="this.$route.path.includes('crear-tipo')"
                        >Crear Tipo de expediente</b-breadcrumb-item
                    >
                    <b-breadcrumb-item
                        tag="router-link"
                        to="/expedientes/editar-tipo"
                        active
                        v-if="this.$route.path.includes('editar-tipo')"
                        >Editar Tipo de expediente</b-breadcrumb-item
                    >
                </b-breadcrumb>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <formulario :entornoId="entornoId" :centroId="centroId"></formulario>
        </div>
    </app-layout>
</template>

<script>
import Formulario from "./formularios/FormularioTipo.vue";

export default {
    components: { Formulario },
    name: "CrearTipo",
    data() {
        return {
            entornoId: parseInt(this.$route.params.entornoId),
            centroId: parseInt(this.$route.params.centroId),
        };
    },
};
</script>
