<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb-item tag="router-link" to="/centros" active>Listado de Centros</b-breadcrumb-item>
                </b-breadcrumb>
                <div class="level-right mr-2">
                    <b-button type="is-primary">
                        <span class="icon mr-2">
                            <i class="far fa-arrow-alt-circle-down"></i>
                        </span>
                        Exportar
                    </b-button>
                </div>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <div class="columns is-desktop">
                <div class="column is-3">
                    <div class="level-left">
                        <b-field style="width: 100%" label="Entorno" horizontal>
                            <multiselect
                                style="min-width: 100%"
                                v-model="entorno"
                                id="entorno"
                                label="nombre"
                                track-by="id"
                                placeholder="Buscar entorno..."
                                selectLabel="Pulsa enter para seleccionar"
                                removeLabel="Pulsa enter para eliminar"
                                open-direction="bottom"
                                :preserve-search="true"
                                :options="entornos"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="1000"
                                :max-height="600"
                                :show-no-results="true"
                                :hide-selected="false">
                                <template slot="tag" slot-scope="{ option, remove }"
                                    ><span class="selectTag"
                                        ><span>{{ option.nombre }}</span
                                        ><span class="ml-2" @click="remove(option)">
                                            <i class="fas fa-times-circle"></i> </span></span
                                ></template>
                                <template slot="afterList"> </template>
                                <span slot="noResult">No se ha encontrado ningún entorno.</span>
                            </multiselect>
                        </b-field>
                    </div>
                </div>
                <div class="column">
                    <div class="level-right">
                        <b-switch v-model="mostrarFiltros">Mostrar filtros</b-switch>
                    </div>
                </div>
            </div>
            <tabla
                :resultado="resultado"
                @eliminarCentro="eliminarCentro"
                :mostrarFiltros="mostrarFiltros"
                :entorno="entorno.id"></tabla>
        </div>
    </app-layout>
</template>

<script>
import { esLector } from "@/services/session";
import api from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import Tabla from "./TablaCentros.vue";
import Multiselect from "vue-multiselect";

export default {
    components: { Multiselect, Tabla },
    name: "ListarCentros",
    computed: {
        esLector,
    },
    data() {
        return {
            resultado: {
                centros: [],
                resultadosPorPagina: 10,
                pagina: 1,
            },
            mostrarFiltros: false,
            entorno: apiEntornos.crear(),
            entornos: [],
        };
    },
    methods: {
        listarCentros() {
            if (this.entorno.id !== undefined && this.entorno.id !== 0) {
                var params = {
                    entornoId: this.entorno.id,
                    path: "centros/listar",
                    limite: 0,
                    pagina: 0,
                    term: "",
                };
                return api.listarTabla(params).then((respuesta) => {
                    this.resultado.centros = respuesta.centros;
                });
            }
        },
        eliminarCentro(centroId) {
            this.confirmarEliminar(centroId);
        },
        mostrarMensaje(msg, tipo) {
            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },

        confirmarEliminar(centroId) {
            this.$buefy.dialog.confirm({
                title: "Atención",
                message: "¿Desea eliminar el centro?",
                confirmText: "Si",
                cancelText: "No",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    api.eliminar(centroId).then((respuesta) => {
                        if (respuesta.error !== undefined) {
                            var msg = "Se ha producido un error: <br>" + respuesta.error;
                            var tipo = "is-danger";
                            this.mostrarMensaje(msg, tipo);
                            return;
                        }
                        this.mostrarMensaje(respuesta.msg, "is-success");
                        this.listarCentros();
                    });
                },
            });
        },
        async cargarEntornos() {
            this.entornos = await apiEntornos.listar();
        },
    },
    watch: {
        "$route.params": {
            handler: function () {
                this.cargarEntornos();
            },
            deep: true,
            immediate: true,
        },
        entorno: {
            handler: function () {
                this.listarCentros();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
