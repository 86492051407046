<template>
    <div>
        <b-table
            :data="isEmpty ? [] : data"
            :bordered="isBordered"
            :striped="isStriped"
            :narrowed="isNarrowed"
            :hoverable="isHoverable"
            :loading="isLoading"
            :focusable="isFocusable"
            :mobile-cards="hasMobileCards"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            default-sort="tipoId"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            :page-input="hasInput"
            :pagination-order="paginationOrder"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce">
            <b-table-column field="tipoId" label="Num." width="40" numeric v-slot="props" sortable>
                {{ props.row.tipoExpedienteId || "" }}
            </b-table-column>

            <b-table-column field="nombre" label="Centro" v-slot="props" sortable :searchable="mostrarFiltros">
                {{ props.row.nombre || "" }}
            </b-table-column>

            <b-table-column field="codigo" label="Código" v-slot="props" sortable :searchable="mostrarFiltros">
                {{ props.row.codigo || "" }}
            </b-table-column>

            <b-table-column
                field="supertipoId"
                label="Supertipo"
                v-slot="props"
                sortable
                :searchable="mostrarFiltros"
                :custom-search="filtrarSupertipos">
                {{ getSupertipoNombre(props.row.superTipoId) }}
            </b-table-column>

            <b-table-column field="estado" label="Estado" v-slot="props" sortable :searchable="mostrarFiltros">
                {{ props.row.estado ? "Activo" : "Inactivo" }}
            </b-table-column>

            <b-table-column field="acciones" label="Acciones" v-slot="props" width="180">
                <b-button
                    type="is-primary"
                    icon-left="edit"
                    icon-pack="far"
                    outlined
                    tag="router-link"
                    :to="`/expedientes/editar-tipo/${entornoId}/${centroId}/${props.row.tipoExpedienteId}` || '#'"
                    >Editar</b-button
                >
                <b-button
                    type="is-danger"
                    icon-left="trash-alt"
                    icon-pack="fas"
                    class="ml-1"
                    @click="eliminarTipo(props.row.tipoExpedienteId)"></b-button>
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">No hay resultados</div>
            </template>
        </b-table>
    </div>
</template>
<script>
import apiExpedientes from "@/services/expedientes.js";

export default {
    props: {
        centroId: Number,
        entornoId: Number,
        tipos: [],
        supertipos: [],
        resultadosPorPagina: Number,
        pagina: Number,
        mostrarFiltros: Boolean,
        entorno: {},
    },
    computed: {
        data() {
            return this.tipos;
        },
        currentPage: {
            get() {
                return this.paginaActual;
            },
            set(value) {
                this.paginaActual = value;
            },
        },
        perPage() {
            return this.resultadosPorPagina;
        },
    },
    data() {
        return {
            isEmpty: false,
            isBordered: false,
            isStriped: true,
            isNarrowed: false,
            isHoverable: true,
            isFocusable: false,
            isLoading: false,
            hasMobileCards: true,
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: false,
            paginationPosition: "bottom",
            defaultSortDirection: "asc",
            sortIcon: "arrow-up",
            sortIconSize: "is-small",
            hasInput: false,
            paginationOrder: "",
            inputPosition: "",
            inputDebounce: "",
            paginaActual: null,
        };
    },
    methods: {
        pulsarLista: function (event) {
            if (event) {
                var elemento = event.target.closest("div.dropdown-trigger").nextSibling.nextSibling;
                elemento.style.position = "fixed";
                elemento.style.top = event.clientY / 2.5 + "px";
                elemento.style.right =
                    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) -
                    event.target.closest("div.dropdown-trigger").getBoundingClientRect().right +
                    70 +
                    "px";
            }
        },
        filtrarEstado(row, input) {
            var estado = this.nombreEstado(row.estado).toLowerCase();
            return input && estado.includes(input.toLowerCase());
        },
        mostrarMensaje(msg, tipo, resumen) {
            this.$buefy.dialog.alert({
                title: msg,
                message: resumen,
                type: tipo,
                hasIcon: false,
                icon: "info-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
            });
        },
        filtrarSupertipos(row, input) {
            var supertipo = this.getSupertipoNombre(row.superTipoId).toLowerCase();
            return input && supertipo.includes(input.toLowerCase());
        },
        getSupertipoNombre(id) {
            if (this.supertipos != undefined) {
                let coincidencia = this.supertipos.find((supertipo) => supertipo.superTipoId === id);
                if (coincidencia != undefined && coincidencia.nombre != undefined) {
                    return coincidencia.nombre;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        async eliminarTipo(tipoId) {
            console.log(tipoId);
            this.$buefy.dialog.confirm({
                title: "Atención",
                message: "¿Desea eliminar el Tipo de expediente?",
                confirmText: "Si",
                cancelText: "No",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    apiExpedientes.eliminarTipo(tipoId, this.entornoId, this.centroId).then((respuesta) => {
                        if (respuesta.error !== undefined) {
                            var msg = "Se ha producido un error: <br>" + respuesta.error;
                            var tipo = "is-danger";
                            this.$buefy.toast.open({ message: msg, type: tipo });
                            return;
                        }
                        this.$buefy.toast.open({ message: "Tipo de expediente eliminado", type: "is-success" });
                        this.$emit("eliminar");
                    });
                },
            });
        },
    },
    async mounted() {
        this.paginaActual = 1;
    },
};
</script>

<style>
.dropdown.dropdown-menu-animation.is-bottom-left.is-active.is-mobile-modal .button.is-primary {
    background-color: #888;
}

div.is-disabled > span {
    padding: 0.375rem 0.8rem;
    color: #888;
    line-height: 2.2rem;
}
</style>
