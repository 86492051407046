<template>
    <b-table
        :data="isEmpty ? [] : data"
        :bordered="isBordered"
        :striped="isStriped"
        :narrowed="isNarrowed"
        :hoverable="isHoverable"
        :loading="isLoading"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="id"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Actual"
        :page-input="hasInput"
        :pagination-order="paginationOrder"
        :page-input-position="inputPosition"
        :debounce-page-input="inputDebounce">
        <b-table-column field="id" label="Num." width="40" numeric v-slot="props" sortable>
            {{ props.row.id }}
        </b-table-column>

        <b-table-column field="nombre" label="Nombre" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.nombre }}
        </b-table-column>

        <b-table-column field="endpoint" label="Endpoint" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.endpoint }}
        </b-table-column>

        <b-table-column
            label="Estado"
            v-slot="props"
            field="estado"
            sortable
            :searchable="mostrarFiltros"
            :custom-search="filtrarEstado">
            <span>
                {{ nombreEstado(props.row.estado) }}
            </span>
        </b-table-column>

        <b-table-column label="Acciones" v-slot="props" width="300">
            <b-button
                type="is-primary"
                icon-left="edit"
                icon-pack="far"
                outlined
                tag="router-link"
                :to="`/entornos/editar/${props.row.id}`">
                Editar
            </b-button>
            <b-button type="is-success" icon-left="file" icon-pack="far" @click="verificarEntorno(props.row.id)">
                Verificar
            </b-button>
            <b-button
                type="is-danger"
                icon-left="trash-alt"
                icon-pack="fas"
                @click="eliminarEntorno(props.row.id)"
                class="ml-1">
            </b-button>
        </b-table-column>

        <template #empty>
            <div class="has-text-centered">No hay resultados</div>
        </template>
    </b-table>
</template>
<script>
import EstadosEntornos from "@/enums/EstadosEntornos";
import apiCentros from "@/services/centros.js";

export default {
    props: {
        resultado: {
            entornos: [],
            resultadosPorPagina: 10,
            pagina: 1,
        },
        mostrarFiltros: Boolean,
    },
    computed: {
        data() {
            return this.resultado.entornos;
        },
        currentPage: {
            get() {
                return this.paginaActual;
            },
            set(value) {
                this.paginaActual = value;
            },
        },
        perPage() {
            return this.resultado.resultadosPorPagina;
        },
    },
    data() {
        return {
            isEmpty: false,
            isBordered: false,
            isStriped: true,
            isNarrowed: false,
            isHoverable: true,
            isFocusable: false,
            isLoading: false,
            hasMobileCards: true,
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: false,
            paginationPosition: "bottom",
            defaultSortDirection: "asc",
            sortIcon: "arrow-up",
            sortIconSize: "is-small",
            hasInput: false,
            paginationOrder: "",
            inputPosition: "",
            inputDebounce: "",
            paginaActual: null,
        };
    },
    methods: {
        editarEntorno(entornoId) {
            this.$router.push("/entornos/editar/" + entornoId);
        },
        eliminarEntorno(entornoId) {
            this.$emit("eliminarEntorno", entornoId);
        },
        nombreEstado(estado) {
            return EstadosEntornos.nombres[estado];
        },
        filtrarEstado(row, input) {
            var estado = this.nombreEstado(row.bloqueado).toLowerCase();
            return input && estado.includes(input.toLowerCase());
        },
        async verificarEntorno(entornoId) {
            this.isLoading = true;
            var tipo = "";
            var msg = "";
            try {
                const response = await apiCentros.contar(entornoId);
                this.isLoading = false;
                tipo = "is-success";
                msg = "Entorno verificado. Centros activos: " + response.total;
            } catch (err) {
                tipo = "is-danger";
                msg = "Error: " + err;
            }

            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },
    },
    mounted() {
        this.paginaActual = this.resultado.paginaActual;
    },
};
</script>
