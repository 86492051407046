import { backend } from "./backend";

function listarTipos(params) {
    return backend
        .get(
            "clienteEntorno&entornoId=" +
                params.entornoOrigen +
                "&centroId=" +
                params.centroOrigen.id +
                "&path=expedientes/listarTipos"
        )
        .then((response) => {
            for (let i = 0; i < response.tiposProceso.length; i++) {
                response.tiposProceso[i].accion = "anadir";
                response.tiposProceso[i].tipoCopia = "completa";
                response.tiposProceso[i].tipoParcial = "tiposProceso";
            }
            return response.tiposProceso;
        });
}

function exportar(entornoOrigen, centroOrigen, importacion, uuid) {
    return backend.post("bpm/exportar", {
        entornoOrigen: entornoOrigen,
        centroOrigen: centroOrigen,
        importacion: importacion,
        uuid: uuid,
    });
}

function importar(entornoDestino, centroDestino, exportacion, uuid) {
    return backend.post("bpm/importar", {
        entornoDestino: entornoDestino,
        centroDestino: centroDestino,
        exportacion: exportacion,
        uuid: uuid,
    });
}

export default {
    listarTipos,
    exportar,
    importar,
};
