<template>
    <form @submit.prevent="insertarTipo">
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Código *">
                    <b-input v-model="tipo.codigo" autofocus maxlength="4" required></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Nombre *">
                    <b-input v-model="tipo.nombre" maxlength="60" required></b-input>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Descripción">
                    <b-input v-model="tipo.descripcion" maxlength="255"></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Supertipo *">
                    <multiselect
                        v-model="superTipoId"
                        label="nombre"
                        track-by="nombre"
                        placeholder="Selecciona supertipo"
                        selectLabel="Pulsa enter para seleccionar"
                        removeLabel="Pulsa enter para eliminar"
                        open-direction="bottom"
                        :preserve-search="true"
                        :options="listaSupertipos"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="1000"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="false">
                        <template slot="tag" slot-scope="{ option, remove }"
                            ><span class="selectTag"
                                ><span>{{ option.nombre }}</span
                                ><span class="ml-2" @click="remove(option)">
                                    <i class="fas fa-times-circle"></i> </span></span
                        ></template>
                        <span slot="noResult">No se ha encontrado ningún supertipo.</span>
                    </multiselect>
                </b-field>
            </div>
            <div class="column">
                <b-switch v-model="tipo.estado" true-value="1" false-value="0" type="is-primary" class="mt-5 pt-3">
                    Estado
                </b-switch>
            </div>
        </div>
        <div class="level flotarDcha">
            <div class="level-right">
                <b-button
                    outlined
                    type="is-danger is-dark"
                    icon-pack="fas"
                    icon-left="ban"
                    class="mr-2 level-item"
                    @click="cancelar()">
                    Cancelar
                </b-button>
                <b-button type="is-primary" native-type="submit" class="is-right">
                    Guardar
                    <span class="icon ml-2">
                        <i class="fas fa-save"></i>
                    </span>
                </b-button>
            </div>
        </div>
    </form>
</template>

<script>
import apiExpedientes from "@/services/expedientes";
import Multiselect from "vue-multiselect";

export default {
    name: "FormularioTipo",
    components: {
        Multiselect,
    },
    props: {
        entornoId: Number,
        centroId: Number,
    },
    data() {
        return {
            tipoExpedienteId: 0,
            superTipoId: {},
            tipo: {
                codigo: "",
                nombre: "",
                descripcion: "",
                estado: 1,
                entornoId: this.entornoId,
                centroId: this.centroId,
            },
            listaSupertipos: [],
        };
    },
    computed: {},

    methods: {
        async insertarTipo() {
            if (this.tipoExpedienteId == 0 || this.tipoExpedienteId == undefined) {
                this.tipo.superTipoId = this.superTipoId.superTipoId;

                let response = await apiExpedientes.insertarTipo(this.tipo);
                if (response) {
                    if (response.insertado == 1) {
                        this.mostrarMensaje("Tipo de expediente insertado correctamente", "is-success");
                        this.$router.push("/expedientes");
                    } else {
                        this.mostrarMensaje("Error al insertar el Tipo de expediente", "is-danger");
                    }
                }
            } else {
                this.tipo.superTipoId = this.superTipoId.superTipoId;

                let response = await apiExpedientes.actualizarTipo(
                    this.tipoExpedienteId,
                    this.entornoId,
                    this.centroId,
                    this.tipo
                );
                if (response) {
                    if (response.actualizado == 1) {
                        this.mostrarMensaje("Tipo de expediente actualizado correctamente", "is-success");
                        this.$router.push("/expedientes");
                    } else {
                        this.mostrarMensaje("Error al actualizar el Tipo de expediente", "is-danger");
                    }
                }
            }
        },
        cancelar() {
            this.$router.push("/expedientes");
        },
        mostrarMensaje(msg, tipo) {
            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },
    },
    async mounted() {
        let respuesta = await apiExpedientes.listarSupertipos(this.tipo.entornoId, this.tipo.centroId);
        this.listaSupertipos = respuesta.supertipos;
        this.tipoExpedienteId = this.$route.params.tipoExpedienteId;
        if (this.tipoExpedienteId != 0 && this.tipoExpedienteId != undefined) {
            let response = await apiExpedientes.cargarTipo(this.tipoExpedienteId, this.entornoId, this.centroId);
            if (response) {
                this.tipo = response;
                this.superTipoId = this.listaSupertipos.find(
                    (supertipo) => supertipo.superTipoId === this.tipo.superTipoId
                );
            } else {
                this.mostrarMensaje("Error al cargar el Tipo de expediente", "is-danger");
            }
        }
    },
};
</script>
