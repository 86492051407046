import Vue from "vue";
import VueRouter from "vue-router";
import { recuperar } from "@/services/login";
import { validarRoles } from "@/services/session";

Vue.use(VueRouter);

const routes = [
    ...require("./inicio").default,
    ...require("./centros").default,
    ...require("./gestionCentros").default,
    ...require("./usuarios").default,
    ...require("./entornos").default,
    ...require("./paises").default,
    ...require("./paquetes").default,
    ...require("./sedes").default,
    ...require("./dashboard").default,
    ...require("./seguimientos").default,
    ...require("./significancias").default,
    ...require("./avisos").default,
    ...require("./expedientes").default,
];

const router = new VueRouter({
    linkActiveClass: "is-active",
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.path == "/login") {
        next();
        return;
    }

    const haySesion = await recuperar();

    if (!haySesion) {
        return false;
    }

    const roles_requeridos = to.meta.roles || [];

    if (!validarRoles(roles_requeridos)) {
        next("/");
    } else {
        next();
    }
});

export default router;
