<template>
    <form @submit.prevent="guardarEntorno">
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Nombre">
                    <b-input v-model="entorno.nombre" autofocus maxlength="50" icon="form-textbox" required placeholder="mientorno"></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Endpoint">
                    <b-input v-model="entorno.endpoint" icon="server" required placeholder="mientorno.esginnova.com"></b-input>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Api Key">
                    <b-input v-model="entorno.apiKey" autofocus maxlength="100" icon="api" placeholder="mira en el repo de configuracion" required></b-input>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Estado">
                    <b-switch v-model="entorno.estado"> Bloqueado </b-switch>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Datos de seguimiento">
                    <b-switch v-model="entorno.datosSeguimiento"> Mostrar </b-switch>
                </b-field>
            </div>
        </div>
        <!-- Guardar -->
        <b-button type="is-primary" native-type="submit" class="mt-5">
            Guardar
            <span class="icon ml-2">
                <i class="fas fa-save"></i>
            </span>
        </b-button>
    </form>
</template>
<script>
import apiEntornos from "@/services/entornos.js";

export default {
    name: "FormularioEntorno",
    props: {
        id: Number,
    },
    computed: {},
    data() {
        return {
            entorno: Object,
            clave2: null,
            mensajeError: "",
            tipoMensaje: "",
            isLoading: false,
        };
    },
    methods: {
        cargar(id) {
            return apiEntornos.cargar(id).then((resultado) => {
                this.entorno = resultado.entorno;
            });
        },
        guardarEntorno() {
            apiEntornos.actualizar(this.entorno).then((resultado) => {
                var msg = "Entorno actualizado.";
                var tipo = "is-success";
                if (!this.entorno.id) msg = "Entorno creado";
                if (resultado.error !== undefined) {
                    msg = "Se ha producido un error: <br>" + resultado.error;
                    tipo = "is-danger";
                    this.mostrarMensaje(msg, tipo);
                    return;
                }
                this.mostrarMensaje(msg, tipo);
                this.$router.push({ path: "/entornos" });
            });
        },
        mostrarMensaje(msg, tipo) {
            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },
    },
    async mounted() {
        if (this.id) {
            this.cargar(this.id);
        } else {
            this.entorno = apiEntornos.crear();
        }
    },
};
</script>
