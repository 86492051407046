// import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
    return {
        id: undefined,
        nombre: "",
        orden: 0,
        codigo: "",
        path: "",
    };
}

function listar(params) {
    return backend
        .get(
            "paquetes/listar&entornoId=" +
                params.entornoId +
                "&limite=" +
                params.limite +
                "&pagina=" +
                params.pagina +
                "&term=" +
                params.term
        )
        .then((response) => {
            return response;
        });
}

function cargar(id, entornoId) {
    return backend.get("paquetes/cargar&id=" + id + "&entornoId=" + entornoId).then((response) => {
        var data = response;
        return data;
    });
}
function actualizar(paquete, entornoId, path) {
    return backend.post(path + "&entornoId=" + entornoId, {
        paquete,
    });
}

function eliminar(id, entornoId) {
    return backend.post("paquetes/eliminar" + "&entornoId=" + entornoId, { id });
}

export default {
    crear,
    listar,
    cargar,
    actualizar,
    eliminar,
};
