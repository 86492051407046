import { backend } from "./backend";

function listarTipos(params) {
    return backend
        .get(
            "clienteEntorno&entornoId=" +
                params.entornoOrigen +
                "&centroId=" +
                params.centroOrigen.id +
                "&path=indicadores/listarTipos"
        )
        .then((response) => {
            for (let i = 0; i < response.tiposEntidad.length; i++) {
                response.tiposEntidad[i].accion = "anadir";
            }
            return response.tiposEntidad;
        });
}

function exportar(entornoOrigen, centroOrigen, importacion, uuid) {
    return backend.post("indicadores/exportar", {
        entornoOrigen: entornoOrigen.id,
        centroOrigen: centroOrigen,
        importacion: importacion,
        uuid: uuid,
    });
}

function importar(entornoDestino, centroDestino, exportacion, uuid) {
    return backend.post("indicadores/importar", {
        entornoDestino: entornoDestino,
        centroDestino: centroDestino,
        exportacion: exportacion,
        uuid: uuid,
    });
}

export default {
    listarTipos,
    exportar,
    importar,
};
