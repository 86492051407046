<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb class="level-left mb-0">
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros">Centros</b-breadcrumb-item>
                        <b-breadcrumb-item tag="router-link" to="/gestion-centros/editar" active>
                            Portal de terceros
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </b-breadcrumb>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <div class="mb-4">
                <BotoneraOpciones class="mb-6"></BotoneraOpciones>
            </div>

            <div class="columns is-desktop">
                <div class="column">
                    <b-field label="URL del portal">
                        <b-input
                            v-model="url"
                            autofocus
                            maxlength="50"
                            icon="form-textbox"
                            required
                            ref="url"
                            readonly
                            disabled></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Estado">
                        <b-switch v-model="activo"> Activo </b-switch>
                    </b-field>
                </div>
            </div>
        </div>
        <div class="level flotarDcha">
            <div class="level-right">
                <b-button
                    outlined
                    type="is-danger is-dark"
                    icon-pack="fas"
                    icon-left="ban"
                    class="mr-2 level-item"
                    @click="cancelar">
                    Cancelar
                </b-button>
                <b-button type="is-primary" native-type="submit" class="is-right" @click="guardar">
                    Guardar
                    <span class="icon ml-2">
                        <i class="fas fa-save"></i>
                    </span>
                </b-button>
            </div>
        </div>
    </app-layout>
</template>
<script>
// import { uuid } from "vue-uuid";
import apiPortal from "@/services/portal.js";
import BotoneraOpciones from "@/views/gestionCentros/BotoneraOpciones.vue";

export default {
    name: "PortalFormulario",
    data() {
        return {
            centroId: null,
            url: null,
            activo: false,
        };
    },
    computed: {},
    components: {
        BotoneraOpciones,
    },
    methods: {
        cancelar() {
            this.$router.push({ path: "/gestion-centros" });
        },
        async guardar() {
            let estructura = {
                centroId: this.$route.params.centroId,
                entornoId: this.$route.params.entornoId,
                url: this.url || "",
                activo: this.activo,
            };
            let response = await apiPortal.guardarPortalCentro(estructura);
            if (response.error) {
                this.showUserError("Hubo un error al guardar");
            } else {
                this.showUserMessage("Inserción realizada con éxito");
                this.$router.push({ path: "/gestion-centros" });
            }
        },
        showUserMessage(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: "is-success",
            });
        },
        showUserError(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: "is-danger",
            });
        },
    },

    async mounted() {
        let portal = await apiPortal.cargarPortalCentro(this.$route.params.entornoId, this.$route.params.centroId);
        if (portal["error"]) {
            console.log("El portal no existe, créalo");
            // Pues me voy patras
        } else {
            if (portal.centroId) {
                this.centroId = portal.centroId;
                this.url = portal.url;
                this.activo = portal.activo;
            }
        }
    },
};
</script>
