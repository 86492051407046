<template>
    <b-table
        :data="isEmpty ? [] : data"
        :bordered="isBordered"
        :striped="isStriped"
        :narrowed="isNarrowed"
        :hoverable="isHoverable"
        :loading="isLoading"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="id"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Actual"
        :page-input="hasInput"
        :pagination-order="paginationOrder"
        :page-input-position="inputPosition"
        :debounce-page-input="inputDebounce">
        <b-table-column field="id" label="Num." width="40" numeric v-slot="props" sortable>
            {{ props.row.id }}
        </b-table-column>

        <b-table-column field="nombre" label="Nombre" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.nombre }}
        </b-table-column>

        <b-table-column field="codigo" label="Código" v-slot="props" sortable :searchable="mostrarFiltros">
            {{ props.row.codigo }}
        </b-table-column>

        <b-table-column label="Acciones" v-slot="props" width="180">
            <b-button
                type="is-primary"
                icon-left="edit"
                icon-pack="far"
                outlined
                tag="router-link"
                :to="`/paquetes/editar/${entornoId.id}/${props.row.id}`"
                >Editar</b-button
            >
            <b-button
                type="is-danger"
                icon-left="trash-alt"
                icon-pack="fas"
                @click="eliminarPaquete(props.row.id)"
                class="ml-1"></b-button>
        </b-table-column>
        <template #empty>
            <div class="has-text-centered">No hay resultados</div>
        </template>
    </b-table>
</template>
<script>
import Roles from "@/enums/Roles";
import EstadosPaquetes from "@/enums/EstadosPaquetes";
import { formatDateTabla } from "@/services/date";
export default {
    props: {
        entornoId: {},
        resultado: {
            paquetes: [],
            resultadosPorPagina: 10,
            pagina: 1,
        },
        mostrarFiltros: Boolean,
    },
    computed: {
        data() {
            return this.resultado.paquetes;
        },
        currentPage: {
            get() {
                return this.paginaActual;
            },
            set(value) {
                this.paginaActual = value;
            },
        },
        perPage() {
            return this.resultado.resultadosPorPagina;
        },
    },
    data() {
        return {
            isEmpty: false,
            isBordered: false,
            isStriped: true,
            isNarrowed: false,
            isHoverable: true,
            isFocusable: false,
            isLoading: false,
            hasMobileCards: true,
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: false,
            paginationPosition: "bottom",
            defaultSortDirection: "asc",
            sortIcon: "arrow-up",
            sortIconSize: "is-small",
            hasInput: false,
            paginationOrder: "",
            inputPosition: "",
            inputDebounce: "",
            paginaActual: null,
        };
    },
    methods: {
        editarPaquete(paqueteId) {
            this.$router.push("/paquetes/editar/" + paqueteId);
        },
        eliminarPaquete(paqueteId) {
            this.$emit("eliminarPaquete", paqueteId);
        },
        nombreRol(rol) {
            return Roles.nombres[rol];
        },
        nombreEstado(estado) {
            return EstadosPaquetes.nombres[estado];
        },
        fechaFormateada(fecha) {
            if (!fecha) return "-";
            return formatDateTabla(fecha);
        },
        filtrarRol(row, input) {
            var rol = this.nombreRol(row.rol).toLowerCase();
            return input && row.rol && rol.includes(input.toLowerCase());
        },
        filtrarEstado(row, input) {
            var estado = this.nombreEstado(row.estado).toLowerCase();
            return input && estado.includes(input.toLowerCase());
        },
    },
    mounted() {
        this.paginaActual = this.resultado.paginaActual;
    },
};
</script>
