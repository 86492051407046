<template>
  <form @submit.prevent="importarEncuestas">
    <div class="columns is-desktop">
      <div class="column is-3">
        <b-field label="Entorno de Referencia">
          <b-select
            placeholder="Entorno"
            icon="server"
            required
            v-model="importacion.entornoOrigen"
          >
            <option
              v-for="entorno in entornos"
              :key="entorno.id"
              :value="entorno.id"
            >
              {{ entorno.nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Centro de Referencia">
          <multiselect
            v-model="importacion.centroOrigen"
            id="ajax"
            label="nombre"
            track-by="id"
            placeholder="Buscar centro..."
            selectLabel="Pulsa enter para seleccionar"
            open-direction="bottom"
            :preserve-search="true"
            :options="centros"
            :multiple="false"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="1000"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="false"
            :disabled="importacion.entornoOrigen == 0"
            @search-change="listarCentros"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="selectTag"
                ><span>{{ option.nombre }}</span
                ><span class="ml-2" @click="remove(option)">
                  <i class="fas fa-times-circle"></i> </span></span
            ></template>
            <template slot="afterList" v-if="tienePaginaSiguiente">
              <div v-observe-visibility="estaAlFinal" />
              Cargando...
            </template>
            <span slot="noResult">No se ha encontrado ningún centro.</span>
          </multiselect>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop mt-5" v-if="importacion.encuestas.length">
      <div class="column p-0">
        <b-field class="mt-1">
          <b-checkbox v-model="importarTodos"><strong class="is-size-5">Seleccionar</strong></b-checkbox>
        </b-field>
      </div>
    </div>
    <div
      v-for="encuesta in importacion.encuestas"
      :key="encuesta.id"
      :encuesta="entorno.id"
      class="columns is-desktop is-vcentered"
    >
      <div class="column p-0">
        <b-field class="mt-1">
          <b-checkbox v-model="encuesta.importar" @input="puedeImportar">{{ encuesta.nombre }}</b-checkbox>
        </b-field>
      </div>
    </div>

    <div class="level-right mr-2">
      <b-button
        type="is-danger"
        outlined
        @click="cancelar"
        class="mt-1 is-right mr-2"
      >
        Cancelar
        <span class="icon ml-2">
          <i class="fas fa-save"></i>
        </span>
      </b-button>
      <b-button
        type="is-primary"
        native-type="submit"
        class="mt-1 is-right"
        :disabled="disableImportar"
      >
        Aceptar
        <span class="icon ml-2 alinearBot">
          <b-icon icon="cog"></b-icon>
        </span>
      </b-button>
    </div>
  </form>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiEncuestas from "@/services/encuestas.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import Multiselect from "vue-multiselect";

export default {
  name: "FormularioEncuestas",
  components: { Multiselect },
  props: {
    id: Number,
    entornoId: Number,
    centroDestino: String,
    entornoDestino: String,
  },
  computed: {
    hayCentros() {
      var centros = Boolean(this.centros.length);
      return !centros;
    },
  },
  data() {
    return {
      centros: [],
      mensajeError: "",
      tipoMensaje: "",
      entorno: apiEntornos.crear(),
      entornos: [],
      centroOrigenSeleccionado: "",
      isLoading: false,
      pagina: 0,
      tienePaginaSiguiente: true,
      importacion: {
        encuestas: [],
        entornoOrigen: 0,
        centroOrigen: apiCentros.crear(),
        entornoDestino: this.entornoId,
        centroDestino: this.id,
      },
      disableImportar: true,
      importarTodos: false,
      datosTodos: false,
    };
  },
  methods: {
    mostrarMensaje(msg, tipo, resumen) {
      if (tipo == "is-danger") {
        this.$buefy.toast.open({
          message: msg,
          type: tipo,
        });
        return;
      }
      this.$buefy.dialog.alert({
        title: msg,
        message: resumen,
        type: tipo,
        hasIcon: false,
        icon: "info-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelar() {
      this.$router.push({ path: "/centros" });
    },
    async listarCentros(term) {
      if (term != undefined && term == "") {
        this.centros = [];
      } else if (term == undefined) {
        term = "";
      }
      var params = {
        entornoId: this.importacion.entornoOrigen,
        path: "centros/listar",
        limite: 50,
        pagina: this.pagina,
        term: term,
      };
      if (term != "") {
        params = {
          entornoId: this.importacion.entornoOrigen,
          path: "centros/listar",
          limite: 50,
          pagina: 0,
          term: term,
        };
      }

      return apiCentros.listar(params).then((respuesta) => {
        if (!respuesta.centros.length) {
          this.tienePaginaSiguiente = false;
        }
        if (term != "") {
          this.centros = respuesta.centros;
          this.tienePaginaSiguiente = false;
          return;
        }
        this.tienePaginaSiguiente = true;
        this.centros = this.centros.concat(respuesta.centros);
      });
    },
    estaAlFinal(alfinal) {
      if (alfinal) {
        this.pagina++;
        this.listarCentros();
      }
    },
    actualizarEntorno() {
      this.pagina = 0;
      this.centros = [];
    },
    importarEncuestas() {
      this.$buefy.dialog.confirm({
        title: "Atención",
        message:
          "Va a importar la información en el entorno <b>" + this.entornoDestino + "</b> y el centro <b>" +
          this.centroDestino +
          "</b>",
        confirmText: "Importar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.procesarImportarEncuestas(),
      });
    },
    cargarEncuestas() {
      apiEncuestas.listarTipos(this.importacion).then((resultado) => {
        this.importacion.encuestas = resultado;
      });
    },
    async procesarImportarEncuestas() {
      var msg = "Importación realizada.";
      var tipo = "is-success";
      var resumen = "Se han importado: ";
      var importacionNormalizada = this.normalizarImportacion();
      var resultadoExportacion = await apiEncuestas.exportar(
        this.importacion.entornoOrigen,
        this.importacion.centroOrigen.id,
        importacionNormalizada.encuestas
      );
      if (resultadoExportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoExportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }
      var resultadoImportacion = await apiEncuestas.importar(
        this.importacion.entornoDestino,
        this.importacion.centroDestino,
        resultadoExportacion
      );
      if (resultadoImportacion.error !== undefined) {
        msg = "Se ha producido un error: <br>" + resultadoImportacion.error;
        tipo = "is-danger";
        this.mostrarMensaje(msg, tipo);
        return;
      }
      this.guardarHistorico(this.importacion, resultadoImportacion);
      resumen = this.maquetarResumen(resultadoImportacion);
      this.mostrarMensaje(msg, tipo, resumen);
      // this.$router.push({ path: "/centros" });
    },
    normalizarImportacion() {
      var res = {};
      var encuestas = [];
      var encuesta = {};
      res["entornoOrigen"] = this.importacion.entornoOrigen;
      res["centroOrigen"] = this.importacion.centroOrigen;
      res["entornoDestino"] = this.importacion.entornoDestino;
      res["centroDestino"] = this.importacion.centroDestino;
      for (let i = 0; i < this.importacion.encuestas.length; i++) {
        if (
          !!Object.getOwnPropertyDescriptor(
            this.importacion.encuestas[i],
            "importar"
          ) &&
          this.importacion.encuestas[i].importar
        ) {
          encuesta = {
            id: this.importacion.encuestas[i].id,
            datos:
              !!Object.getOwnPropertyDescriptor(
                this.importacion.encuestas[i],
                "datos"
              ) && this.importacion.encuestas[i].datos
                ? this.importacion.encuestas[i].datos
                : false,
            accion: this.importacion.encuestas[i].accion,
          };
          encuestas.push(encuesta);
        }
      }
      res["encuestas"] = encuestas;
      return res;
    },
    guardarHistorico(importacion, resultado) {
      var historicoImportaciones = apiHistoricoImportaciones.crear();
      var res = JSON.stringify(resultado);
      historicoImportaciones.aplicacion = "encuestas";
      historicoImportaciones.entornoDestinoId = importacion.entornoDestino;
      historicoImportaciones.centroDestinoId = importacion.centroDestino;
      historicoImportaciones.centroDestino = this.centroDestino;
      historicoImportaciones.entornoOrigenId = importacion.entornoOrigen;
      historicoImportaciones.centroOrigenId = importacion.centroOrigen.id;
      historicoImportaciones.centroOrigen = this.centroOrigenSeleccionado;
      historicoImportaciones.importacion = this.limpiarImportacion(
        importacion,
        resultado
      );
      historicoImportaciones.resultado = res;

      apiHistoricoImportaciones.insertar(historicoImportaciones);
    },
    cambiarCentroOrigen() {
      this.centroOrigenSeleccionado = this.importacion.centroOrigen.nombre;
      this.cargarEncuestas();
    },
    limpiarImportacion(importacion, res) {
      var resultado = {};
      for (var prop in importacion) {
        if (
          !!Object.getOwnPropertyDescriptor(importacion[prop], "importar") &&
          importacion[prop].importar
        ) {
          resultado[prop] = {
            accion: importacion[prop].accion,
            resultado: res[prop].resultado,
          };
        }
      }
      return JSON.stringify(resultado);
    },
    puedeImportar() {
      var res =
        Boolean(
          this.importacion.entornoOrigen &&
            this.importacion.centroOrigen.id != undefined &&
            this.importacion.centroOrigen.id
        ) && this.hayEncuestasSeleccionadas();
      this.disableImportar = !res;
    },
    hayEncuestasSeleccionadas() {
      for (let i = 0; i < this.importacion.encuestas.length; i++) {
        if (
          !!Object.getOwnPropertyDescriptor(
            this.importacion.encuestas[i],
            "importar"
          ) &&
          this.importacion.encuestas[i].importar
        ) {
          return true;
        }
      }
      return false;
    },
    maquetarResumen(resumen) {
      var res = "";
      var fila = "";
      var icono = "";
      for (var aplicacion in resumen) {
        icono = '<i class="fas fa-check has-text-success is-size-3"></i>';
        if (resumen[aplicacion].resultado != "ok")
          icono =
            '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
        fila =
          '<div class="columns"><div class="column is-1">' +
          icono +
          '</div><div class="column is-11">' +
          resumen[aplicacion].mensaje +
          "</div></div>";
        res += fila;
      }
      return res;
    },
    marcarImportarTodos() {
      for(let i = 0;i < this.importacion.encuestas.length; i++){
        this.importacion.encuestas[i].importar = this.importarTodos;
      }
      this.puedeImportar();
    },
    marcarDatosTodos() {
      for(let i = 0;i < this.importacion.encuestas.length; i++){
        this.importacion.encuestas[i].datos = this.datosTodos;
      }
      this.puedeImportar();
    },
  },
  async mounted() {
    this.entornos = await apiEntornos.listar();
  },
  watch: {
    "importacion.centroOrigen": function () {
      this.puedeImportar();
      this.cambiarCentroOrigen();
    },
    "importacion.entornoOrigen": function () {
      this.puedeImportar();
      this.actualizarEntorno();
    },
    "importacion.encuestas": {
      handler: function () {
        this.puedeImportar();
      },
      deep: true,
      immediate: true,
    },
    importarTodos: function () {
      this.marcarImportarTodos();
    },
    datosTodos: function () {
      this.marcarDatosTodos();
    },
  },
};
</script>
<style>
.dialog.modal .modal-card {
  width: 50vw;
  max-width: 50vw;
}
</style>
