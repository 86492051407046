<template>
    <section>
        <div class="buttons">
            <b-button
                tag="router-link"
                :to="`/gestion-centros/editar/ficha/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Ficha
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/editar/configuracion/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Configuración
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/editar/aplicaciones/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Aplicaciones
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/portal/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Portal de terceros
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/public/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Portal público
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/editar/expedientes/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Tipos de Expedientes
            </b-button>
            <b-button
                tag="router-link"
                :to="`/gestion-centros/editar/integraciones/${$route.params.centroId}/${$route.params.entornoId}`"
                type="is-light"
                size="is-small">
                Integraciones
            </b-button>
        </div>
    </section>
</template>
<script>
export default {
    components: {},
    name: "BotoneraOpciones",
};
</script>
