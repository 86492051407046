import { backend } from "./backend";
import * as session from "./session";

export async function login(email, clave) {
  const response = await backend.post("acceso/login", { email, clave });
  session.iniciarSesion(response);
  return response;
}

export async function recuperar() {
  try {
    const credenciales = await backend.get("acceso/recuperar");
    if (credenciales.nombre) {
      session.iniciarSesion(credenciales);
      return true;
    }
  } catch(e) {
    console.log("error al recuperar la sesion", e);
    console.log("posiblemente la sesion no este iniciada");
  }
  return false;
}

export async function logout() {
  session.logout();
}
