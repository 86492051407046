import { backend } from "./backend";

function crearSupertipo() {
    return {
        id: 0,
        nombre: "",
        orden: 0,
        estado: 0,
    };
}

function listarSupertipos(entornoId, centroId) {
    return backend
        .get("expedientes/listarSupertipos&entornoId=" + entornoId + "&centroId=" + centroId)
        .then((response) => {
            var data = response;
            return data;
        });
}

function listarTipos(entornoId, centroId) {
    return backend.get("expedientes/listarTipos&entornoId=" + entornoId + "&centroId=" + centroId).then((response) => {
        var data = response;
        return data;
    });
}

function insertarSupertipo(params) {
    return backend.post("expedientes/insertarSupertipo", {
        params,
    });
}

function insertarTipo(params) {
    return backend.post("expedientes/insertarTipo", {
        params,
    });
}

function actualizarSupertipo(supertipoId, entornoId, centroId, params) {
    return backend.post(
        "expedientes/actualizarSupertipo&supertipoId=" +
            supertipoId +
            "&entornoId=" +
            entornoId +
            "&centroId=" +
            centroId,
        {
            params,
        }
    );
}

function actualizarTipo(tipoId, entornoId, centroId, params) {
    return backend.post(
        "expedientes/actualizarTipo&tipoId=" + tipoId + "&entornoId=" + entornoId + "&centroId=" + centroId,
        {
            params,
        }
    );
}

function cargarSupertipo(supertipoId, entornoId, centroId) {
    return backend
        .get(
            "expedientes/cargarSupertipo&supertipoId=" +
                supertipoId +
                "&entornoId=" +
                entornoId +
                "&centroId=" +
                centroId
        )
        .then((response) => {
            return response;
        });
}

function cargarTipo(tipo, entornoId, centroId) {
    return backend
        .get("expedientes/cargarTipo&tipoExpedienteId=" + tipo + "&entornoId=" + entornoId + "&centroId=" + centroId)
        .then((response) => {
            return response;
        });
}

function eliminarSupertipo(supertipoId, entornoId, centroId) {
    return backend.post("expedientes/eliminarSupertipo", {
        params: {
            supertipoId: supertipoId,
            entornoId: entornoId,
            centroId: centroId,
        },
    });
}

function eliminarTipo(tipoId, entornoId, centroId) {
    return backend.post("expedientes/eliminarTipo", {
        params: {
            tipoExpedienteId: tipoId,
            entornoId: entornoId,
            centroId: centroId,
        },
    });
}

export default {
    insertarTipo,
    crearSupertipo,
    listarSupertipos,
    listarTipos,
    insertarSupertipo,
    cargarSupertipo,
    cargarTipo,
    actualizarSupertipo,
    actualizarTipo,
    eliminarSupertipo,
    eliminarTipo,
};
