<template>
    <form @submit.prevent="guardarPaquete">
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Nombre *">
                    <b-input v-model="paquete.nombre" autofocus maxlength="60" required></b-input>
                </b-field>
            </div>

            <div class="column">
                <b-field label="Descripcion *">
                    <b-input v-model="paquete.descripcion" maxlength="255" required></b-input>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Código *">
                    <b-input v-model="paquete.codigo" autofocus maxlength="4" required></b-input>
                </b-field>
            </div>

            <div class="column">
                <b-field label="Orden *">
                    <b-input v-model="paquete.orden" type="number" maxlength="11" required></b-input>
                </b-field>
            </div>
        </div>

        <b-button type="is-primary" native-type="submit" class="mt-5 is-right">
            Guardar
            <span class="icon ml-2">
                <i class="fas fa-save"></i>
            </span>
        </b-button>
    </form>
</template>
<script>
import apiPaquetes from "@/services/paquetes.js";

export default {
    name: "FormularioPaquete",
    props: {
        id: Number,
        entornoId: Number,
    },
    data() {
        return {
            paquete: Object,
            mensajeError: "",
            tipoMensaje: "",
            isLoading: false,
        };
    },
    methods: {
        cargar(id, entornoId) {
            return apiPaquetes.cargar(id, entornoId).then((resultado) => {
                this.paquete = resultado;
            });
        },
        guardarPaquete() {
            let path = "paquetes/insertar";
            let msg = "Paquete creado.";
            if (this.paquete.id != undefined) {
                path = "paquetes/actualizar";
                msg = "Paquete actualizado.";
            }

            apiPaquetes.actualizar(this.paquete, parseInt(this.$route.params.entornoId), path).then((resultado) => {
                var tipo = "is-success";
                if (resultado.insertado) {
                    msg = "Paquete creado.";
                } else if (resultado.actualizado) {
                    msg = "Paquete actualizado.";
                } else {
                    msg = "Se ha producido un error";
                    tipo = "is-danger";
                    this.mostrarMensaje(msg, tipo);
                    return;
                }

                this.mostrarMensaje(msg, tipo);
                this.$router.push({ path: "/paquetes" });
            });
        },
        mostrarMensaje(msg, tipo) {
            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },
    },
    async mounted() {
        if (this.id) {
            this.cargar(this.id, this.$props.entornoId);
        } else {
            this.paquete = apiPaquetes.crear();
        }
    },
};
</script>
