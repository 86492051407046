export default [
    {
        path: "/paquetes",
        component: require("@/views/paquetes/ListarPage").default,
    },
    {
        path: "/paquetes/editar/:entornoId/:id",
        component: require("@/views/paquetes/EditarPage").default,
    },
    {
        path: "/paquetes/crear/:entornoId",
        component: require("@/views/paquetes/CrearPage").default,
    },
];
