<template>
    <form @submit.prevent="insertarSupertipo">
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Código *">
                    <b-input v-model="supertipo.codigo" autofocus maxlength="4" required></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Nombre *">
                    <b-input v-model="supertipo.nombre" maxlength="60" required></b-input>
                </b-field>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <b-field label="Descripción">
                    <b-input v-model="supertipo.descripcion" maxlength="255"></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Orden *">
                    <b-input v-model="supertipo.orden" type="number" maxlength="11" required></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-switch v-model="supertipo.estado" true-value="1" false-value="0" type="is-primary" class="mt-5 pt-3">
                    Estado
                </b-switch>
            </div>
        </div>
        <div class="level flotarDcha">
            <div class="level-right">
                <b-button
                    outlined
                    type="is-danger is-dark"
                    icon-pack="fas"
                    icon-left="ban"
                    class="mr-2 level-item"
                    @click="cancelar()">
                    Cancelar
                </b-button>
                <b-button type="is-primary" native-type="submit" class="is-right">
                    Guardar
                    <span class="icon ml-2">
                        <i class="fas fa-save"></i>
                    </span>
                </b-button>
            </div>
        </div>
    </form>
</template>

<script>
import apiExpedientes from "@/services/expedientes";

export default {
    name: "FormularioSupertipo",
    components: {},
    props: {
        entornoId: Number,
        centroId: Number,
    },
    data() {
        return {
            idSupertipo: 0,
            supertipo: {
                codigo: "",
                nombre: "",
                descripcion: "",
                orden: 0,
                estado: 1,
                entornoId: this.entornoId,
                centroId: this.centroId,
            },
        };
    },
    computed: {},

    methods: {
        async insertarSupertipo() {
            if (this.idSupertipo == 0 || this.idSupertipo == undefined) {
                let response = await apiExpedientes.insertarSupertipo(this.supertipo);
                if (response) {
                    if (response.insertado == 1) {
                        this.mostrarMensaje("Supertipo insertado correctamente", "is-success");
                        this.$router.push("/expedientes");
                    } else {
                        this.mostrarMensaje("Error al insertar el supertipo", "is-danger");
                    }
                }
            } else {
                let response = await apiExpedientes.actualizarSupertipo(
                    this.idSupertipo,
                    this.entornoId,
                    this.centroId,
                    this.supertipo
                );
                if (response) {
                    if (response.actualizado == 1) {
                        this.mostrarMensaje("Supertipo actualizado correctamente", "is-success");
                        this.$router.push("/expedientes");
                    } else {
                        this.mostrarMensaje("Error al actualizar el supertipo", "is-danger");
                    }
                }
            }
        },
        cancelar() {
            this.$router.push("/expedientes");
        },
        mostrarMensaje(msg, tipo) {
            this.$buefy.toast.open({
                message: msg,
                type: tipo,
            });
        },
    },
    async mounted() {
        this.idSupertipo = this.$route.params.supertipoId;
        if (this.idSupertipo != 0 && this.idSupertipo != undefined) {
            let response = await apiExpedientes.cargarSupertipo(this.idSupertipo, this.entornoId, this.centroId);
            if (response) {
                this.supertipo = response;
            } else {
                this.mostrarMensaje("Error al cargar el supertipo", "is-danger");
            }
        }
    },
};
</script>
