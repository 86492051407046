<template>
    <div>
        <form @submit.prevent="importarIndicadores">
            <div class="columns is-desktop">
                <div class="column is-3">
                    <b-field label="Entorno de Referencia">
                        <multiselect
                            v-model="entornoOrigen"
                            id="entorno"
                            label="nombre"
                            @select="actualizarCentrosSelect"
                            track-by="nombre"
                            placeholder="Buscar entorno..."
                            selectLabel="Pulsa enter para seleccionar"
                            removeLabel="Pulsa enter para eliminar"
                            open-direction="bottom"
                            :preserve-search="true"
                            :options="entornos"
                            :multiple="false"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="1000"
                            :max-height="600"
                            :show-no-results="true"
                            :hide-selected="false">
                            <template slot="tag" slot-scope="{ option, remove }"
                                ><span class="selectTag"
                                    ><span>{{ option.nombre }}</span
                                    ><span class="ml-2" @click="remove(option)">
                                        <i class="fas fa-times-circle"></i> </span></span
                            ></template>
                            <template slot="afterList" v-if="tienePaginaSiguiente">
                                <div v-observe-visibility="estaAlFinal" />
                                Cargando...
                            </template>
                            <span slot="noResult">No se ha encontrado ningún entorno.</span>
                        </multiselect>

                        <!-- <b-select placeholder="Entorno" icon="server" required v-model="entornoOrigen">
                            <option v-for="entorno in entornos" :key="entorno.id" :value="entorno.id">
                                {{ entorno.nombre }}
                            </option>
                        </b-select> -->
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Centro de Referencia">
                        <multiselect
                            v-model="centroOrigen"
                            id="ajax"
                            label="nombre"
                            track-by="id"
                            placeholder="Buscar centro..."
                            selectLabel="Pulsa enter para seleccionar"
                            removeLabel="Pulsa enter para eliminar"
                            open-direction="bottom"
                            :preserve-search="true"
                            :options="centros"
                            :multiple="false"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="1000"
                            :max-height="600"
                            :show-no-results="true"
                            :hide-selected="false"
                            :disabled="!entornoOrigen"
                            @search-change="listarCentros">
                            <template slot="tag" slot-scope="{ option, remove }"
                                ><span class="selectTag"
                                    ><span>{{ option.nombre }}</span
                                    ><span class="ml-2" @click="remove(option.id)">
                                        <i class="fas fa-times-circle"></i> </span></span
                            ></template>
                            <template slot="afterList" v-if="tienePaginaSiguiente">
                                <div v-observe-visibility="estaAlFinal" />
                                Cargando...
                            </template>
                            <span slot="noResult">No se ha encontrado ningún centro.</span>
                        </multiselect>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <strong>Seleccione la información a importar</strong>
                </div>
                <div class="column">
                    <strong>Acción al importar</strong>
                </div>
            </div>

            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.permisos.importar">Permisos</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.permisos.accion"
                            :disabled="!importacion.permisos.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.configuracion.importar">Configuración</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.configuracion.accion"
                            :disabled="!importacion.configuracion.importar">
                            <option value="sustituir">Sustituir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.camposYMagnitudes.importar">Campos y Magnitudes</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.camposYMagnitudes.accion"
                            :disabled="!importacion.camposYMagnitudes.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.etiquetas.importar">Etiquetas</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.etiquetas.accion"
                            :disabled="!importacion.etiquetas.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.unidadesOrganizativas.importar"
                            >Unidades Organizativas</b-checkbox
                        >
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.unidadesOrganizativas.accion"
                            :disabled="!importacion.unidadesOrganizativas.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.artefactos.importar">Artefactos</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.artefactos.accion"
                            :disabled="!importacion.artefactos.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-desktop">
                <div class="column">
                    <b-field class="mt-5">
                        <b-checkbox v-model="importacion.indicadores.importar">Indicadores</b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field class="mt-4">
                        <b-select
                            placeholder="Elija acción..."
                            icon="file-replace"
                            v-model="importacion.indicadores.accion"
                            :disabled="!importacion.indicadores.importar">
                            <option value="sustituir">Sustituir</option>
                            <option value="anadir">Añadir</option>
                        </b-select>
                    </b-field>
                </div>
            </div>

            <div class="level-right mr-2">
                <b-button type="is-danger" outlined @click="cancelar" class="mt-1 is-right mr-2">
                    Cancelar
                    <span class="icon ml-2">
                        <i class="fas fa-save"></i>
                    </span>
                </b-button>
                <b-button type="is-primary" native-type="submit" class="mt-1 is-right" :disabled="disableImportar">
                    Aceptar
                    <span class="icon ml-2 alinearBot">
                        <b-icon icon="cog"></b-icon>
                    </span>
                </b-button>
            </div>
        </form>
        <modal-progreso
            :faseProceso="faseProceso"
            :textoProgreso="textoProgreso"
            :valorProgreso="valorProgreso"
            :mostrarBarraProgreso="mostrarBarraProgreso"></modal-progreso>
    </div>
</template>
<script>
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiIndicadores from "@/services/indicadores.js";
import apiHistoricoImportaciones from "@/services/historicoImportaciones.js";
import apiEstatusImportaciones from "@/services/estatusImportaciones.js";
import Multiselect from "vue-multiselect";
import ModalProgreso from "@/components/ModalProgreso.vue";
import { uuid } from "vue-uuid";

export default {
    name: "FormularioIndicadores",
    components: { Multiselect, ModalProgreso },
    props: {
        id: Number,
        entornoId: Number,
        centroDestino: String,
        entornoDestino: String,
    },
    computed: {
        hayCentros() {
            var centros = Boolean(this.centros.length);
            return !centros;
        },
    },
    data() {
        return {
            centros: [],
            mensajeError: "",
            tipoMensaje: "",
            entorno: apiEntornos.crear(),
            entornos: [],
            centroOrigenSeleccionado: "",
            isLoading: false,
            pagina: 0,
            tienePaginaSiguiente: true,
            importacion: {
                permisos: {
                    importar: false,
                    accion: "sustituir",
                },
                configuracion: {
                    importar: false,
                    accion: "sustituir",
                },
                camposYMagnitudes: {
                    importar: false,
                    accion: "sustituir",
                },
                etiquetas: {
                    importar: false,
                    accion: "sustituir",
                },
                unidadesOrganizativas: {
                    importar: false,
                    accion: "sustituir",
                },
                artefactos: {
                    importar: false,
                    accion: "sustituir",
                },
                indicadores: {
                    importar: false,
                    accion: "sustituir",
                },
            },
            entornoOrigen: 0,
            centroOrigen: apiCentros.crear(),
            entornoDestinoId: this.entornoId,
            centroDestinoId: this.id,
            disableImportar: true,
            mostrarBarraProgreso: false,
            textoProgreso: "",
            valorProgreso: 0,
            faseProceso: "",
        };
    },
    methods: {
        mostrarMensaje(msg, tipo, resumen) {
            if (tipo == "is-danger") {
                this.$buefy.toast.open({
                    message: msg,
                    type: tipo,
                });
                return;
            }
            this.$buefy.dialog.alert({
                title: msg,
                message: resumen,
                type: tipo,
                hasIcon: false,
                icon: "info-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
            });
        },
        actualizarCentrosSelect() {
            if (this.entornoOrigen != null) {
                this.puedeImportar();
                this.actualizarEntorno();
            }
        },
        cancelar() {
            this.$router.push({ path: "/centros" });
        },

        async listarCentros(term) {
            if (term != undefined && term == "") {
                this.centros = [];
            } else if (term == undefined) {
                term = "";
            }
            var params = {
                entornoId: this.entornoOrigen.id,
                path: "centros/listar",
                limite: 50,
                pagina: this.pagina,
                term: term,
            };
            if (term != "") {
                params = {
                    entornoId: this.entornoOrigen.id,
                    path: "centros/listar",
                    limite: 50,
                    pagina: 0,
                    term: term,
                };
            }

            if (params.entornoId == undefined || params.entornoId == 0) {
                return;
            }
            return apiCentros.listar(params).then((respuesta) => {
                if (respuesta.centros != undefined) {
                    if (!respuesta.centros.length) {
                        this.tienePaginaSiguiente = false;
                    }
                }
                if (term != "") {
                    this.centros = respuesta.centros;
                    this.tienePaginaSiguiente = false;
                    return;
                }
                this.tienePaginaSiguiente = true;
                this.centros = this.centros.concat(respuesta.centros);
            });
        },
        estaAlFinal(alfinal) {
            if (alfinal) {
                this.pagina++;
                this.listarCentros();
            }
        },
        actualizarEntorno() {
            this.pagina = 0;
            this.centros = [];
        },
        importarIndicadores() {
            this.$buefy.dialog.confirm({
                title: "Atención",
                message:
                    "Va a importar la información en el entorno <b>" +
                    this.entornoDestino +
                    "</b> y el centro <b>" +
                    this.centroDestino +
                    "</b>",
                confirmText: "Importar",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.procesarImportarIndicadores(),
            });
        },
        async procesarImportarIndicadores() {
            var msg = "Importación realizada.";
            var tipo = "is-success";
            var resumen = "Se han importado: ";
            this.uuid = uuid.v1();
            this.faseProceso = "Exportación";
            this.valorProgreso = 100 / Object.keys(this.importacion).length;
            this.textoProgreso = Object.keys(this.importacion)[0];

            this.mostrarProgreso();

            var resultadoExportacion = await apiIndicadores.exportar(
                this.entornoOrigen,
                this.centroOrigen.id,
                this.importacion,
                this.uuid
            );
            if (resultadoExportacion.error !== undefined) {
                msg = "Se ha producido un error: <br>" + resultadoExportacion.error;
                tipo = "is-danger";
                this.mostrarMensaje(msg, tipo);
                return;
            }

            this.uuid = uuid.v1();
            this.faseProceso = "Importación";
            this.valorProgreso = 100 / Object.keys(this.importacion).length;
            this.textoProgreso = Object.keys(this.importacion)[0];
            this.mostrarProgreso(false);

            var resultadoImportacion = await apiIndicadores.importar(
                this.entornoDestinoId,
                this.centroDestinoId,
                resultadoExportacion,
                this.uuid
            );
            if (resultadoImportacion.error !== undefined) {
                msg = "Se ha producido un error: <br>" + resultadoImportacion.error;
                tipo = "is-danger";
                this.mostrarMensaje(msg, tipo);
                return;
            }
            this.guardarHistorico(this.importacion, resultadoImportacion);
            resumen = this.maquetarResumen(resultadoImportacion);
            this.mostrarBarraProgreso = false;
            this.mostrarMensaje(msg, tipo, resumen);
            // this.$router.push({ path: "/centros" });
        },

        guardarHistorico(importacion, resultado) {
            var historicoImportaciones = apiHistoricoImportaciones.crear();
            var res = JSON.stringify(resultado);
            historicoImportaciones.aplicacion = "indicadores";
            historicoImportaciones.entornoDestinoId = this.entornoDestinoId;
            historicoImportaciones.centroDestinoId = this.centroDestinoId;
            historicoImportaciones.centroDestino = this.centroDestino;
            historicoImportaciones.entornoOrigenId = this.entornoOrigen.id;
            historicoImportaciones.centroOrigenId = this.centroOrigen.id;
            historicoImportaciones.centroOrigen = this.centroOrigenSeleccionado;
            historicoImportaciones.importacion = this.limpiarImportacion(importacion, resultado);
            historicoImportaciones.resultado = res;

            apiHistoricoImportaciones.insertar(historicoImportaciones);
        },
        cambiarCentroOrigen() {
            this.centroOrigenSeleccionado = this.centroOrigen.nombre;
        },
        limpiarImportacion(importacion, res) {
            var resultado = {};
            for (var prop in importacion) {
                if (!!Object.getOwnPropertyDescriptor(importacion[prop], "importar") && importacion[prop].importar) {
                    const result = prop.replace(/([A-Z])/g, " $1");
                    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    resultado[prop] = {
                        accion: importacion[prop].accion,
                        resultado: res[prop].resultado,
                        nombre: finalResult,
                    };
                }
            }
            return JSON.stringify(resultado);
        },
        puedeImportar() {
            var res =
                Boolean(this.entornoOrigen && this.centroOrigen.id != undefined && this.centroOrigen.id) &&
                this.hayIndicadoresSeleccionadas();
            this.disableImportar = !res;
        },
        hayIndicadoresSeleccionadas() {
            for (let elemento in this.importacion) {
                if (
                    !!Object.getOwnPropertyDescriptor(this.importacion[elemento], "importar") &&
                    this.importacion[elemento].importar
                ) {
                    return true;
                }
            }
            return false;
        },
        maquetarResumen(resumen) {
            var res = "";
            var fila = "";
            var icono = "";
            for (var aplicacion in resumen) {
                icono = '<i class="fas fa-check has-text-success is-size-3"></i>';
                if (resumen[aplicacion].resultado != "ok")
                    icono = '<i class="fas fa-exclamation-triangle has-text-danger is-size-3"></i>';
                fila =
                    '<div class="columns"><div class="column is-1">' +
                    icono +
                    '</div><div class="column is-11">' +
                    resumen[aplicacion].mensaje +
                    "</div></div>";
                res += fila;
            }
            return res;
        },
        async mostrarProgreso(importacion = true, that = this) {
            apiEstatusImportaciones.cargarProgreso(this.uuid).then((respuesta) => {
                if (
                    (importacion && (respuesta.total == 0 || respuesta.progreso < respuesta.total)) ||
                    (!importacion && that.mostrarBarraProgreso)
                ) {
                    that.mostrarBarraProgreso = true;
                    if (respuesta.mensaje != "") that.textoProgreso = respuesta.mensaje;
                    if (respuesta.valorProgreso != 0) that.valorProgreso = (100 * respuesta.progreso) / respuesta.total;
                    setTimeout(function () {
                        that.mostrarProgreso(importacion);
                    }, 1000);
                }
            });
        },
    },
    async mounted() {
        this.entornos = await apiEntornos.listar();
    },
    watch: {
        centroOrigen: function () {
            this.puedeImportar();
            this.cambiarCentroOrigen();
        },

        importacion: {
            handler: function () {
                this.puedeImportar();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
<style>
.dialog.modal .modal-card {
    width: 50vw;
    max-width: 50vw;
}
</style>
