<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb-item tag="router-link" to="/centros" active>Gestión de expedientes</b-breadcrumb-item>
                </b-breadcrumb>
                <div class="level-right mr-2"></div>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <form>
                <div class="columns is-desktop">
                    <div class="column is-3">
                        <b-field label="Entorno">
                            <multiselect
                                v-model="entorno"
                                id="entorno"
                                label="nombre"
                                @select="actualizarCentrosSelect"
                                track-by="nombre"
                                placeholder="Buscar entorno..."
                                selectLabel="Pulsa enter para seleccionar"
                                removeLabel="Pulsa enter para eliminar"
                                open-direction="bottom"
                                :preserve-search="true"
                                :options="entornos"
                                :multiple="false"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="1000"
                                :max-height="600"
                                :show-no-results="true"
                                :hide-selected="false">
                                <template slot="tag" slot-scope="{ option, remove }"
                                    ><span class="selectTag"
                                        ><span>{{ option.nombre }}</span
                                        ><span class="ml-2" @click="remove(option)">
                                            <i class="fas fa-times-circle"></i> </span></span
                                ></template>
                                <template slot="afterList" v-if="tienePaginaSiguiente">
                                    <div v-observe-visibility="estaAlFinal" />
                                    Cargando...
                                </template>
                                <span slot="noResult">No se ha encontrado ningún entorno.</span>
                            </multiselect>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Centro">
                            <multiselect
                                v-model="centro"
                                id="ajax"
                                label="nombre"
                                track-by="id"
                                placeholder="Buscar centro..."
                                selectLabel="Pulsa enter para seleccionar"
                                removeLabel="Pulsa enter para eliminar"
                                open-direction="bottom"
                                :preserve-search="true"
                                :options="centros"
                                :multiple="false"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="1000"
                                :max-height="600"
                                :show-no-results="true"
                                :hide-selected="false"
                                :disabled="!entorno"
                                @search-change="listarCentros"
                                @select="setEntornoYCentro">
                                <template slot="tag" slot-scope="{ option, remove }"
                                    ><span class="selectTag"
                                        ><span>{{ option.nombre }}</span
                                        ><span class="ml-2" @click="remove(option.id)">
                                            <i class="fas fa-times-circle"></i> </span></span
                                ></template>
                                <template slot="noOptions">No se ha encontrado ningún centro</template>
                                <span slot="noResult">No se ha encontrado ningún centro.</span>
                            </multiselect>
                        </b-field>
                    </div>
                </div>
            </form>
            <div class="mt-5">
                <b-tabs type="is-boxed" expanded v-model="activeTab">
                    <b-tab-item label="Tipos de expedientes">
                        <div class="column">
                            <div class="level-right">
                                <b-switch v-model="mostrarFiltroTipos" class="mr-5">Mostrar filtros</b-switch>
                                <b-tooltip
                                    :active="!puedeCrearTipo"
                                    label="Seleccione entorno y centro para crear un nuevo Tipo de Expediente"
                                    position="is-top"
                                    type="is-dark"
                                    multilined>
                                    <b-button
                                        :disabled="!puedeCrearTipo"
                                        type="is-primary"
                                        tag="router-link"
                                        :to="`/expedientes/crear-tipo/${entorno.id}/${centro.id}`">
                                        <span class="icon mr-2">
                                            <i class="fas fa-plus-circle"></i>
                                        </span>
                                        Nuevo Tipo de expediente
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </div>
                        <tabla-tipos
                            :tipos="listaTipos.tiposProceso"
                            :supertipos="listaSupertipos.supertipos"
                            :centroId="centro.id"
                            :entornoId="entorno.id"
                            :resultadosPorPagina="10"
                            :mostrar-filtros="mostrarFiltroTipos"
                            @eliminar="eliminar"></tabla-tipos>
                    </b-tab-item>
                    <b-tab-item label="Supertipos">
                        <div class="column">
                            <div class="level-right">
                                <b-switch v-model="mostrarFiltrosSupertipos" class="mr-5">Mostrar filtros</b-switch>
                                <b-tooltip
                                    :active="!puedeCrearSupertipo"
                                    label="Seleccione entorno y centro para crear un nuevo Supertipo"
                                    position="is-top"
                                    type="is-dark"
                                    multilined>
                                    <b-button
                                        :disabled="!puedeCrearSupertipo"
                                        type="is-primary"
                                        tag="router-link"
                                        :to="`/expedientes/crear-supertipo/${entorno.id}/${centro.id}`">
                                        <span class="icon mr-2">
                                            <i class="fas fa-plus-circle"></i>
                                        </span>
                                        Nuevo Supertipo
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </div>
                        <tabla-supertipos
                            :supertipos="listaSupertipos.supertipos"
                            :centroId="centro.id"
                            :entornoId="entorno.id"
                            :resultadosPorPagina="10"
                            :mostrar-filtros="mostrarFiltrosSupertipos"
                            @eliminar="eliminar"></tabla-supertipos>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </app-layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import apiCentros from "@/services/centros.js";
import apiEntornos from "@/services/entornos.js";
import apiExpedientes from "@/services/expedientes.js";
import TablaSupertipos from "./TablaSupertipos.vue";
import TablaTipos from "./TablaTipos.vue";

export default {
    name: "ListadoSupertipos",
    components: { Multiselect, TablaSupertipos, TablaTipos },
    props: {},
    data() {
        return {
            entorno: 0,
            isLoading: false,
            centros: [],
            entornos: [],
            centro: apiCentros.crear(),
            tienePaginaSiguiente: true,
            activeTab: 0,
            listaSupertipos: [],
            listaTipos: [],
            mostrarFiltrosSupertipos: false,
            mostrarFiltroTipos: false,
        };
    },
    computed: {
        hayCentros() {
            var centros = Boolean(this.centros.length);
            return !centros;
        },
        puedeCrearSupertipo() {
            return this.entorno.id && this.centro.id;
        },
        puedeCrearTipo() {
            return (
                this.entorno.id &&
                this.centro.id &&
                this.listaSupertipos.supertipos != undefined &&
                this.listaSupertipos.supertipos.length > 0
            );
        },
    },
    methods: {
        eliminar() {
            this.setEntornoYCentro();
        },
        async setEntornoYCentro() {
            apiExpedientes.listarSupertipos(this.entorno.id, this.centro.id).then((respuesta) => {
                this.listaSupertipos = respuesta;
            });
            apiExpedientes.listarTipos(this.entorno.id, this.centro.id).then((respuesta) => {
                this.listaTipos = respuesta;
            });
        },
        async listarCentros(term) {
            if (term != undefined && term == "") {
                this.centros = [];
            } else if (term == undefined) {
                term = "";
            }
            var params = {
                entornoId: this.entorno.id,
                path: "centros/listar",
                limite: 50,
                pagina: this.pagina,
                term: term,
            };
            if (term != "") {
                params = {
                    entornoId: this.entorno.id,
                    path: "centros/listar",
                    limite: 50,
                    pagina: 0,
                    term: term,
                };
            }

            if (params.entornoId == undefined || params.entornoId == 0) {
                return;
            }
            return apiCentros.listar(params).then((respuesta) => {
                if (respuesta.centros != undefined) {
                    if (!respuesta.centros.length) {
                        this.tienePaginaSiguiente = false;
                    }
                }
                if (term != "") {
                    this.centros = respuesta.centros;
                    this.tienePaginaSiguiente = false;
                    return;
                }
                this.tienePaginaSiguiente = true;
                this.centros = this.centros.concat(respuesta.centros);
            });
        },
        actualizarCentrosSelect() {
            if (this.entorno != null) {
                this.actualizarEntorno();
            }
        },
        actualizarEntorno() {
            this.pagina = 0;
            this.centros = [];
            this.centro = apiCentros.crear();
            this.listarCentros();
        },
        estaAlFinal(alfinal) {
            if (alfinal) {
                this.pagina++;
                this.listarCentros();
            }
        },
    },
    async mounted() {
        this.entornos = await apiEntornos.listar();
    },
};
</script>
