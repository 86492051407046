import { backend } from "./backend";

function cargarPortalCentro(entornoId, centroId) {
    return backend.get("gesuto/configuracionPortal", {
        entornoId: entornoId,
        centroId: centroId,
    });
}

function guardarPortalCentro(params) {
    return backend.post("gesuto/guardarConfiguracionPortal", { params });
}

export default {
    cargarPortalCentro,
    guardarPortalCentro,
};
