import { listado } from "./listado";
import { backend } from "./backend";

function crear() {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var fechaFin = new Date(year + 1, month, day);

    return {
        id: 0,
        nombre: "",
        usuarioId: 0,
        id_pais: 0,
        sedeId: 0,
        fechaIni: new Date(),
        fechaFin: fechaFin,
        estado: "Activo",
        editor_only_office: "Activo",
        modo_editor: 2,
        tieneChat: "Inactivo",
        lista_idiomas: [],
        idiomaId: [],
        alcance: 0,
        centrosRelacionados: [],
        helpdesk_customer_user_id: "",
        tipo_centro: 0,
        centroFacturable: 2,
        estadoSuscripcion: 1,
        minimo_caracteres_pass: 7,
        strength_min_pass: 70,
        max_checklists_vigentes: 3,
    };
}

function contar(entornoId) {
    return backend.get("centros/contar" + "&entornoId=" + entornoId + "&path=centros/contar").then((response) => {
        return response;
    });
}

function listar(params) {
    return backend
        .get(
            "centros/listar&entornoId=" +
                params.entornoId +
                "&path=" +
                params.path +
                "&limite=" +
                params.limite +
                "&pagina=" +
                params.pagina +
                "&term=" +
                params.term +
                "&idsCentros="
        )
        .then((response) => {
            return response;
        });
}

function listarTabla(params) {
    return backend
        .get(
            "centros/listarTabla&entornoId=" +
                params.entornoId +
                "&path=" +
                params.path +
                "&limite=" +
                params.limite +
                "&pagina=" +
                params.pagina +
                "&term=" +
                params.term
        )
        .then((response) => {
            return response;
        });
}

function cargarConIds(params) {
    var data = {
        centrosIds: params.centrosIds,
    };
    return backend
        .post("clienteEntorno&entornoId=" + params.entornoId + "&path=" + params.path, { data: data })
        .then((response) => {
            return response;
        });
}

function cargar(centroId, entornoId) {
    return backend.get("centros/cargar&centroId=" + centroId + "&entornoId=" + entornoId).then((response) => {
        if (response.centro.editor_only_office == 1) {
            response.centro.editor_only_office = "Activo";
        } else {
            response.centro.editor_only_office = "Inactivo";
        }
        if (response.centro.estado == 1) {
            response.centro.estado = "Activo";
        } else {
            response.centro.estado = "Inactivo";
        }
        response.centro.centrosRelacionados = response.centrosRelacionados;
        if (response.perfilesCentro.length > 0) {
            response.centro.numAdm = buscarNumUsuarios(1, response.perfilesCentro);
            response.centro.numResp = buscarNumUsuarios(2, response.perfilesCentro);
            response.centro.numOper = buscarNumUsuarios(3, response.perfilesCentro);
            response.centro.numLec = buscarNumUsuarios(4, response.perfilesCentro);
        }

        response.centro.modeloPredeterminado = response.centro.modeloPredeterminadoBSC;

        if (response.configuracionChat.chat_activado == 1) {
            response.centro.tieneChat = "Activo";
        } else {
            response.centro.tieneChat = "Inactivo";
        }
        response.centro.numCanal = response.configuracionChat.chat_canal;
        response.centro.id_responsable_cartera = response.responsable.id_responsable;
        response.centro.minimo_caracteres_pass = response.centro.caracteres_min_pass;
        response.centro.numero_maximo_usuarios_radar =
            response.centro.numeroMaximoUsuariosRadar != "" ? response.centro.numeroMaximoUsuariosRadar : 0;

        response.centro.radar = Boolean(response.centro.radar);
        response.centro.activar_limite_usuarios_radar = Boolean(response.centro.numeroMaximoUsuariosRadar);
        response.centro.multisede = Boolean(response.centro.multisede);
        response.centro.vincular_entidad_grupo_org = Boolean(response.centro.vincular_entidad_grupo_org);
        response.centro.diversificar_por_entidad = Boolean(response.centro.diversificar_por_entidad);
        response.centro.diversificar_por_carpeta = Boolean(response.centro.diversificar_por_carpeta);
        response.centro.bloquear_roles = Boolean(response.centro.bloquear_roles);
        response.centro.sincronizar_gcontactos = Boolean(response.centro.sincronizar_gcontactos);
        response.centro.importacion_documentos = Boolean(response.centro.importacion_documentos);
        response.centro.copiar_tipos_expedientes = Boolean(response.centro.copiar_tipos_expedientes);
        response.centro.rrhh_permisos_campos = Boolean(response.centro.rrhh_permisos_campos);
        response.centro.bpm_filtro_defecto = Boolean(response.centro.bpm_filtro_defecto);
        response.centro.seg_avanz_acc = Boolean(response.centro.seg_avanz_acc);
        response.centro.login2fa = response.login2fa;
        response.centro.login2fa_recordar_dispositivo = response.login2fa_recordar_dispositivo;
        response.centro.import_manual_doc_exp = Boolean(response.centro.import_manual_doc_exp);
        response.centro.correos_sin_hipervinculos = Boolean(response.centro.correos_sin_hipervinculos);
        response.centro.registros_multiples = Boolean(response.centro.registros_multiples);
        response.centro.limit_diez_col_vista_bpm = Boolean(response.centro.limit_diez_col_vista_bpm);
        response.centro.centro_copia_controlada = Boolean(response.centro.centro_copia_controlada);
        response.centro.limitarModelosGestion = Boolean(response.centro.limitarModelosGestion);
        response.centro.centro_integridad_acciones = Boolean(response.centro.centro_integridad_acciones);
        response.centro.gdrive_bpm = Boolean(response.centro.gdrive_bpm);
        if (
            response.features.bpm_analisis_causas_con_inteligencia_artificial == undefined ||
            response.features.bpm_analisis_causas_con_inteligencia_artificial == "false"
        ) {
            response.features.bpm_analisis_causas_con_inteligencia_artificial = false;
        } else {
            response.features.bpm_analisis_causas_con_inteligencia_artificial = Boolean(
                response.features.bpm_analisis_causas_con_inteligencia_artificial
            );
        }

        if (
            response.features.bpm_filtrado_entidades_relacionadas_por_jerarquia == undefined ||
            response.features.bpm_filtrado_entidades_relacionadas_por_jerarquia == "false"
        ) {
            response.features.bpm_filtrado_entidades_relacionadas_por_jerarquia = false;
        } else {
            response.features.bpm_filtrado_entidades_relacionadas_por_jerarquia = Boolean(
                response.features.bpm_filtrado_entidades_relacionadas_por_jerarquia
            );
        }

        response.centro.tiene_correlativo_global = Boolean(response.centro.tiene_correlativo_global);
        response.centro.relacion_centros = Boolean(response.centro.relacion_centros);
        response.centro.centro_copia_controlada = Boolean(response.tieneCopiaControlada);
        response.centro.centro_integridad_acciones = Boolean(response.integridadAcciones);
        response.centro.numeroMaximoModelosRiesgos = parseInt(response.centro.numeroMaximoModelosRiesgos);
        response.centro.vistaCompleja = Boolean(response.centro.vistaCompleja);

        if (response.office365.estado !== undefined && Boolean(response.office365.estado)) {
            response.centro.estado_office365 = true;
            response.centro.appid = response.office365.appid;
            response.centro.tenantid = response.office365.tenantid;
            response.centro.secretkey = response.office365.secretkey;
            response.centro.scope = response.office365.scope;
        }

        response.centro.combo_cola_helpdesk = response.centro.cola_helpdesk;

        if (response.vidsigner.activo !== undefined && Boolean(response.vidsigner.activo)) {
            response.centro.vs_activo = true;
            response.centro.vs_username = response.vidsigner.userName;
            response.centro.vs_password = response.vidsigner.password;
            response.centro.vs_subscription_name = response.vidsigner.subscription_name;
            response.centro.vs_subscription_pass = response.vidsigner.subscription_pass;
            response.centro.vs_enrollment_code = response.vidsigner.enrollment_code;
            response.centro.vs_base_url = response.vidsigner.base_url;
        }

        response.centro.qlik_toggle = Boolean(response.centro.con_qlik);
        response.conexionesQlikProcesadas = procesarConexiones(response.conexionesQlik.cuentas);

        return response;
    });
}

function insertar(centro) {
    return backend.post("centros/insertar", {
        centro,
    });
}

function actualizar(centro) {
    return backend.post("centros/actualizar", {
        centro,
    });
}

function eliminar(id) {
    return backend.post("centros/eliminar", { id });
}

function autocompletar(params) {
    return listado("centros/autocompletar", params);
}

function cargarDatosAuxiliares(entornoId) {
    return backend.get("centros/cargarDatosAuxiliares&entornoId=" + entornoId).then((response) => {
        for (var tipoExpediente in response.datos.tiposProcesosGenericos) {
            response.datos.tiposProcesosGenericos[tipoExpediente].seleccionado = false;
        }
        return response.datos;
    });
}

function guardarCentro(params) {
    return backend.post("centros/guardar", {
        params,
    });
}

function obtenerOpcionesComboCentroEntorno(entornoId, entorno) {
    return backend.post("centros/obtenerOpcionesComboCentroEntorno", {
        entornoId: entornoId,
        entorno: entorno,
    });
}

function guardarRegistroCreacionCentro(params) {
    return backend.post("centros/guardarRegistroCreacionCentro", {
        registro: params,
    });
}

function cargarRegistroCreacionCentro(entornoId, centroId) {
    return backend.post("centros/cargarRegistroCreacionCentro", {
        entornoId: entornoId,
        centroId: centroId,
    });
}

function buscarNumUsuarios(tipo, perfiles) {
    for (var perfil in perfiles) {
        if (perfiles[perfil].id_perfil == tipo) {
            return perfiles[perfil].numero;
        }
    }
    return 5;
}

function procesarConexiones(conexiones) {
    var resultado = [];
    var tipo = "";
    for (var conexion in conexiones) {
        if (conexiones[conexion].usaServidorQlik > 0) {
            tipo = conexiones[conexion].servidorQlikId;
        } else {
            tipo = "ams";
        }
        resultado.push({
            qlik_descripcion: conexiones[conexion].descripcion,
            qlik_tipo: tipo,
            qlik_usuarioQlik: conexiones[conexion].usuarioQlik,
            qlik_qlikUserDirectory: conexiones[conexion].qlikUserDirectory,
            usuarioServicio: conexiones[conexion].usuarioServicio,
            claveServicio: conexiones[conexion].claveServicio,
        });
    }
    return resultado;
}

export default {
    contar,
    crear,
    listar,
    listarTabla,
    cargar,
    insertar,
    actualizar,
    eliminar,
    autocompletar,
    cargarConIds,
    cargarDatosAuxiliares,
    guardarCentro,
    obtenerOpcionesComboCentroEntorno,
    guardarRegistroCreacionCentro,
    cargarRegistroCreacionCentro,
};
