<template>
    <app-layout>
        <div class="box container is-fluid minh-100">
            <div class="level mb-0">
                <b-breadcrumb class="level-left mb-0">
                    <b-breadcrumb-item tag="router-link" to="/paquetes">Paquetes</b-breadcrumb-item>
                    <b-breadcrumb-item tag="router-link" to="/paquetes/editar" active>Editar</b-breadcrumb-item>
                </b-breadcrumb>
            </div>
            <div class="divider is-primary is-right mt-2"></div>
            <formulario-paquete :id="id" :entorno-id="entornoId"></formulario-paquete>
        </div>
    </app-layout>
</template>

<script>
import FormularioPaquete from "./Formulario.vue";

export default {
    components: { FormularioPaquete },
    name: "EditarPaquete",
    data() {
        return {
            id: parseInt(this.$route.params.id),
            entornoId: parseInt(this.$route.params.entornoId),
        };
    },
};
</script>
